import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./axiosConfig";
import VueApexCharts from "vue3-apexcharts";
import vuetify from "./plugins/vuetify";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueApexCharts);
app.use(vuetify);
app.mount("#app");
