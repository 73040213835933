
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives'


export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#55c08b',
          secondary: '#a7cc48',
          accent: '#c08c55',
          error: '#D0021B',
          info: '#2AB3BA',
          success: '#7ED321',
          warning: '#F5A623',
        },
        variables: {
          'hover-opacity': 0.04,
        },
      },
    },
  },
})
