<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
              <v-expansion-panel-title>
                <span class="title-icons mdi mdi-earth text-primary"></span>
                <div class="expansion-title text-primary">List of Countries</div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="expansion-content">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" class="mt-0 mr-10" @click="newCountry" variant="outlined" rounded="lg"  size="small">
                    <v-icon left class="mr-3">mdi-plus-circle</v-icon> Add Country
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col cols="12" class="country-table">
                    <v-data-table :headers="countryHeaders" :items="countries" :search="countrySearch" density="compact">
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.actions="{ item }">
                        <div class="actions-container">
                          <v-btn size="x-small" class="" color="error"  variant="outlined" rounded="lg"
                            @click="confirmDeleteCountryDialog(item)">
                            Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel :disabled="isStudiesPanelDisabled">
              <v-expansion-panel-title>
                <span class="title-icons mdi mdi-book-open text-primary"></span>
                <div class="expansion-title text-primary">List of Studies</div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="expansion-content">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" class="mt-0 mr-10" @click="newStudy" variant="outlined" rounded="lg" size="small">
                    <v-icon class="mr-3">mdi-plus-box</v-icon> Add Study
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table :headers="studyHeaders" :items="processedStudies" :search="studySearch" density="compact">
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.actions="{ item }">
                        <div class="actions-container">
                          <v-btn size="x-small" class="ma-2" color="accent" @click="updateStudy(item)" variant="outlined" rounded="lg">
                            Update <v-icon dark class="ml-2">mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn size="x-small" :disabled="!item.is_deletable" class="ma-2" color="error" variant="outlined" rounded="lg"
                            @click="confirmDeleteStudyDialog(item)">
                            Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel :disabled="isGroupsPanelDisabled">
              <v-expansion-panel-title>
                <span class="title-icons mdi mdi-account-group text-primary"></span>
                <div class="expansion-title text-primary">List of Groups</div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="expansion-content">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" class="mt-0 mr-10" @click="newGroup" variant="outlined" rounded="lg" size="small">
                    <v-icon left class="mr-3">mdi-plus-circle</v-icon> Add Group
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table :headers="groupHeaders" :items="processedGroups" :search="groupSearch" density="compact">
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.actions="{ item }">
                        <div class="actions-container">
                          <v-btn size="x-small" class="ma-2" color="accent" variant="outlined" rounded="lg" @click="updateGroup(item)">
                            Update <v-icon dark class="ml-2">mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn size="x-small" :disabled="!item.is_deletable" class="ma-2" color="error" variant="outlined" rounded="lg"
                            @click="confirmDeleteGroupDialog(item)">
                            Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel :disabled="isUsersPanelDisabled">
              <v-expansion-panel-title>
                <span class="title-icons mdi mdi-account-multiple text-primary"></span>
                <div class="expansion-title text-primary">List of Users</div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="expansion-content">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" class="mt-0 mr-10" @click="newUser" variant="outlined" rounded="lg" size="small">
                    <v-icon left class="mr-3">mdi-account-plus</v-icon> Add User
                  </v-btn>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table :headers="userHeaders" :items="users" :search="userSearch" density="compact">
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.actions="{ item }">
                        <div class="actions-container">
                          <v-btn size="x-small" class="mr-2" color="accent" variant="outlined" rounded="lg" text @click="updateUser(item)">
                            Update <v-icon dark class="ml-2">mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn size="x-small" :disabled="item.id === user.id || !item.is_active" class=""
                            color="error" variant="outlined" rounded="lg" @click="confirmDeleteUserDialog(item)">
                            Delete <v-icon dark class="ml-2">mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="newCountryDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5 text-primary">New Country</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="countryCreated.name" label="Country Name*" required variant="underlined"
                color="primary"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions class="actions-container">
          <v-spacer></v-spacer>
          <v-btn @click="newCountryDialog=false" color="error">Close</v-btn>
          <v-btn :disabled="!countryFormValid" @click="createCountry()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newStudyDialog" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <span class="text-h5 mt-3 text-primary">New Study</span>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn size="x-small" icon @click="newStudyDialog=false" class="close-button">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-stepper>
          <template v-slot:default="{ prev, next }">
            <v-stepper-header style="pointer-events: none;">
              <v-stepper-item :complete="step > 1" :editable="step >= 1" title="Initial Settings"
                value="1"></v-stepper-item>
              <v-divider></v-divider>
              <v-stepper-item :complete="step > 2" :editable="step >= 2" title="Folder Settings"
                value="2"></v-stepper-item>
              <v-divider></v-divider>
              <v-stepper-item editable title="Confirm" value="3"></v-stepper-item>
            </v-stepper-header>
            <v-stepper-window>
              <v-stepper-window-item value="1">
                <v-card-text>
                  <v-container>
                    <v-form ref="step1Form">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="studyCreated.name" :rules="studyNameRules" label="Study Name*" required
                          variant="underlined" color="primary"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="studyCreated.countryId" :rules="countryRules" :items="countryListItems"
                            label="Select Countries*" item-title="text" item-text="text" item-value="value" multiple
                            required variant="underlined" color="primary" attach chips small-chips></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select v-model="studyCreated.regionId" :rules="regionRules" :items="regionListItems"
                            label="Select Bucket Region*" item-title="text" item-text="text" item-value="value" required
                            variant="underlined" color="primary" attach chips small-chips></v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                  <small>* indicates required field</small>
                </v-card-text>
                <v-card-actions class="actions-container">
                  <v-btn color="primary" @click="nextStep(next)">Next</v-btn>
                </v-card-actions>
              </v-stepper-window-item>
              <v-stepper-window-item value="2">
                <v-card-text class="text-center">
                  <folder-tree :upload="false" :left="true" :editable="true" :opened="false"></folder-tree>
                </v-card-text>
                <v-card-actions class="actions-container">
                  <v-btn color="secondary" @click="prevStep(prev)">Back</v-btn>
                  <v-btn color="primary" @click="nextStep(next)">Next</v-btn>
                </v-card-actions>
              </v-stepper-window-item>
              <v-stepper-window-item value="3">
                <v-row>
                  <v-col cols="12">
                    <h2 class="ml-7">Review and Confirm Study Details</h2>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="4">
                            <label><strong>Name:</strong> {{ studyCreated.name }}</label>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <label><strong>Country:</strong> {{ getCountryNamesByIds(studyCreated.countryId) }}</label>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <label><strong>Region:</strong> {{ getRegionDescriptionById(studyCreated.regionId) }}
                            </label>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <label style="text-wrap: nowrap;"><strong>File Structure:</strong></label>
                          </v-col>
                          <v-col cols="9" style="margin: -4% 5% 0 0">
                            <folder-tree :upload="false" :left="false" :editable="false" :opened="true"></folder-tree>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions class="actions-container">
                      <v-btn color="secondary" @click="prevStep(prev)">Back</v-btn>
                      <v-btn color="primary" @click="completeStepper">Save</v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-stepper-window-item>
            </v-stepper-window>
          </template>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newGroupDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5 text-primary">New Group</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="groupCreated.name" label="Group Name*" required variant="underlined"
                color="primary"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="groupCreated.studyId" :items="studyListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Study*" variant="underlined" color="primary" required></v-select>
              </v-col>
              <v-col cols="12">
                <v-select v-model="groupCreated.regionId" :items="regionListItems" label="Select Bucket Region*"
                  item-title="text" item-text="text" item-value="value" required variant="underlined" color="primary"
                  attach chips small-chips>
                </v-select>
              </v-col>
              <!-- <v-col cols="12">
                <v-select v-model="groupCreated.visible_group_list" :items="groupListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Visible Groups" multiple attach chips></v-select>
              </v-col> -->
            </v-row>
          </v-container>
          <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="newGroupDialog=false" color="error">Close</v-btn>
          <v-btn :disabled="!groupFormValid" @click="createGroup()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newUserDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5 text-primary">New User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="userCreated.email" label="E-mail*" type="email" :rules="emailRules" required
                variant="underlined" color="primary"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userCreated.first_name" label="First Name" variant="underlined" color="primary"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userCreated.last_name" label="Last Name*" variant="underlined" color="primary" persistent-hint
                  required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="userCreated.password" label="Password*" type="password" :rules="passwordRules"
                variant="underlined" color="primary" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userCreated.role_id" :items="roleListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Role*" required variant="underlined" color="primary"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userCreated.study_group_id" :items="groupListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Group*" required variant="underlined" color="primary"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox v-model="userCreated.is_active" label="User Active*" color="primary" required></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="newUserDialog=false" color="error">Close</v-btn>
          <v-btn :disabled="!userCreatedFormValid" @click="createUser()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateStudyDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5 text-primary">Update Study</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="studySelected.name" label="Study Name*" required variant="underlined" color="primary"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="studySelected.study_countries" :items="countryListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Countries*" multiple required variant="underlined" color="primary" attach
                  chips small-chips>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select v-model="studyCreated.regionId" :rules="regionRules" :items="regionListItems"
                  label="Select Bucket Region*" item-title="text" item-text="text" item-value="value" required
                  attach chips small-chips variant="underlined" color="primary"></v-select>
              </v-col>
              <small class="ml-2">* indicates required field</small>
              <v-col cols="12" class="mt-4">
                <label style="text-wrap: nowrap;"><strong>File Structure:</strong></label>
                <folder-tree :upload="false" :left="false" :editable="true" :opened="false"></folder-tree>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateStudyDialog=false" color="error">Close</v-btn>
          <v-btn @click="saveStudyChanges()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateGroupDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5 text-primary">Update Group</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="groupSelected.name" label="Group Name*" required variant="underlined" color="primary"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="groupSelected.study" :items="studyListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Study*" required variant="underlined" color="primary"></v-select>
              </v-col>
              <!-- <v-col cols="12">
                <v-select v-model="groupSelected.visible_group_list" :items="groupListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Visible Groups" multiple attach chips></v-select>
              </v-col> -->
            </v-row>
          </v-container>
          <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateGroupDialog=false" color="error">Close</v-btn>
          <v-btn @click="saveGroupChanges()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateUserDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title>
          <span class="text-h5 text-primary">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userSelected.first_name" label="First Name" persistent-hint variant="underlined" color="primary"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="userSelected.last_name" label="Last Name*" persistent-hint variant="underlined" color="primary"
                  required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field variant="underlined" color="primary" v-model="userSelected.email" label="E-mail*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userSelected.role_id" :items="roleListItems" item-title="text" item-text="text"
                  item-value="value" label="Select Role*" required variant="underlined" color="primary"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="userSelected.study_group_id" :items="groupListItems" item-title="text"
                  item-text="text" item-value="value" label="Select Group*" required variant="underlined" color="primary"></v-select>
              </v-col>
              <v-col cols="12" sm="12" v-if="userEmail !== userSelected.email">
                <v-checkbox v-model="userSelected.is_active" label="User Active*" required></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateUserDialog=false" color="error">Close</v-btn>
          <v-btn @click="saveDataUser()" color="primary">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCountryDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentCountry.name }} country?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteCountryDialog=false" color="error">No</v-btn>
          <v-btn @click="deleteCountry(currentCountry.id)" color="primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteStudyDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentStudy.name }} study?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteStudyDialog=false" color="error">No</v-btn>
          <v-btn @click="deleteStudy(currentStudy.id)" color="primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteGroupDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentGroup.name }} group?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteGroupDialog=false" color="error">No</v-btn>
          <v-btn @click="deleteGroup(currentGroup.id)" color="primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteUserDialog" max-width="900px" :retain-focus="false" persistent>
      <v-card class="dialog-style">
        <v-card-title class="text-h6 justify-center">
          Are you sure to delete {{ currentUser.email }} user?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteUserDialog=false" color="error">No</v-btn>
          <v-btn @click="deleteUser(currentUser.id)" color="primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
import { mapGetters } from 'vuex';
import { roleManager } from "@/store/roleManager";
import { ROLES } from "@/store/roleConstants";
import { toast } from "vue3-toastify";
import FolderTree from '@/components/generic/FolderTree.vue';

export default {
  name: "AdminView",
  components: {
    FolderTree
  },
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);

    return {
      userServices: userServicesInstance,
    };
  },
  data() {
    return {
      step: 1,
      panels: [0],
      isStudiesPanelDisabled: true,
      isGroupsPanelDisabled: true,
      isUsersPanelDisabled: true,
      showUsers: false,
      drawer: true,
      countrySearch: "",
      studySearch: "",
      groupSearch: "",
      userSearch: "",
      countries: [],
      countryListItems: [],
      groupListItems: [],
      studyListItems: [],
      roleListItems: [],
      regionListItems: [],
      studies: [],
      groups: [],
      users: [],
      userLoggedEmail: "",
      regions: [],
      newCountryDialog: false,
      newStudyDialog: false,
      newGroupDialog: false,
      newUserDialog: false,
      updateStudyDialog: false,
      updateGroupDialog: false,
      updateUserDialog: false,
      deleteCountryDialog: false,
      deleteStudyDialog: false,
      deleteGroupDialog: false,
      deleteUserDialog: false,
      currentCountry: { name: "" },
      currentStudy: "",
      currentGroup: null,
      currentUser: "",
      countryCreated: { name: "" },
      studyCreated: { name: "", studyId: null, countryId: [], regionId: null },
      groupCreated: { name: "", groupId: null, studyId: null, visible_group_list: [], regionId: null, visible_group_list_name: [] },
      userCreated: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role_id: null,
        role_name: "",
        study_group_id: null,
        study_group_name: "",
        is_active: false,
      },
      studySelected: { study_countries: [] },
      groupSelected: { id: null, name: "", study: null, regionId: null, visible_group_list: [], visible_group_list_name: [] },
      userSelected: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role_id: null,
        role_name: "",
        study_group_id: null,
        study_group_name: "",
        is_active: false,
      },
      countryHeaders: [
        { title: "Country", key: "name" },
        { title: "", key: "actions", align: "right" },
      ],
      studyHeaders: [
        { title: "Study Name", key: "name" },
        { title: "Countries", key: "study_countries_name" },
        { title: "Bucket Region", key: "bucket_region_description" },
        { title: "", key: "actions", align: "right" },
      ],
      groupHeaders: [
        { title: "Group Name", key: "name" },
        { title: "Study", key: "study_name" },
        { title: "Bucket Region", key: "bucket_region_description" },
        { title: "", key: "actions", align: "right" },
      ],
      userHeaders: [
        { title: "Email", key: "email" },
        { title: "First Name", key: "first_name" },
        { title: "Last Name", key: "last_name" },
        { title: "Role", key: "role_name" },
        { title: "Group", key: "study_group_name" },
        { title: "", value: "actions", align: "right" },
      ],
      error: "",
      disabled: false,
      role: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be more than 6 characters",
      ],
      studyNameRules: [
        v => !!v || 'Study Name is required',
        v => /^[a-zA-Z0-9]*$/.test(v) || 'Study Name must not contain special characters or spaces',
      ],
      countryRules: [
        (v) =>
          (v && v.length > 0) || 'At least one country must be selected'
      ],
      regionRules: [
        (v) =>
          (v != null) || 'At least one region must be selected'
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
    userCreatedFormValid() {
      const isEmailValid =
        this.userCreated.email && /.+@.+\..+/.test(this.userCreated.email);
      const isPasswordValid =
        this.userCreated.password && this.userCreated.password.length >= 6;
      return (
        isEmailValid &&
        isPasswordValid &&
        !!this.userCreated.first_name &&
        !!this.userCreated.last_name &&
        this.userCreated.role_id !== undefined &&
        this.userCreated.study_group_id !== undefined &&
        this.userCreated.is_active !== undefined
      );
    },
    countryFormValid() {
      return (
        this.countryCreated &&
        this.countryCreated.name &&
        this.countryCreated.name.length > 0
      );
    },
    groupFormValid() {
      return (
        this.groupCreated &&
        this.groupCreated.name &&
        this.groupCreated.name.length > 0
      );
    },
    studyFormValid() {
      return (
        this.studyCreated &&
        this.studyCreated.name &&
        this.studyCreated.name.length > 0
      );
    },
    canExpandStudies() {
      return this.countries.length > 0;
    },
    canExpandGroups() {
      return this.studies.length > 0 && this.canExpandStudies;
    },
    canExpandUsers() {
      return this.groups.length > 0 && this.canExpandGroups;
    },
    processedStudies() {
      if (!Array.isArray(this.studies)) {
        console.error('Expected this.studies to be an array, but got:', this.studies);
        return [];
      }
      return this.studies.map(study => ({
        ...study,
        study_countries_name: this.getCountryNamesByIds(study.study_countries || []),
        region_description: this.getRegionDescriptionById(study.regionId),
      }));
    },
    processedGroups() {
      return this.groups.map(group => ({
        ...group,
        study_name: this.getStudyNameById(group.study),
        region_description: this.getRegionDescriptionById(group.regionId),
      }));
    },
    userEmail() {
      return this.userLoggedEmail
    }
  },
  async mounted() {
    this.getRoles();
    this.$store.commit('setLoading', true);
    await this.userServices
      .checkUserData()
      .then((response) => {
        this.userLoggedEmail = response.data.email;
        if (response.data.role !== ROLES.ADMIN) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          this.$store.commit('setLoading', false);
        }, 1700);
      });
    await Promise.all([
      this.getCountries(),
      this.getRegion(),
      this.getStudies(),
      this.getGroups(),
      this.getUsers(),
    ]);
    this.initialPanelStates();
  },
  watch: {
    countries(newVal) {
      this.isStudiesPanelDisabled = newVal.length === 0;
      this.updatePanels();
    },
    studies(newVal) {
      this.isGroupsPanelDisabled =
        this.countries.length === 0 || newVal.length === 0;
      this.updatePanels();
    },
    groups(newVal) {
      this.isUsersPanelDisabled =
        this.countries.length === 0 ||
        this.studies.length === 0 ||
        newVal.length === 0;
      this.updatePanels();
    },
  },
  methods: {
    getRoles() {
      this.roleListItems = roleManager.roleList().map((role) => ({
        value: role.id,
        text: role.text,
      }));
    },
    showElementsInToolbar(elementsToShow) {
      if (elementsToShow === "users") {
        this.showUsers = !this.showUsers;
      }
    },
    newCountry() {
      this.countryCreated = { name: "" };
      this.newCountryDialog = true;
    },
    newStudy() {
      this.studyCreated = { name: "", studyId: null, countryId: [], regionId: null };
      this.countryListItems = this.countries.map((country) => ({
        value: country.id,
        text: country.name,
      }));
      this.newStudyDialog = true;
    },
    newGroup() {
      this.groupCreated = { name: "", groupId: null, studyId: null, regionId: null, visible_group_list: [], visible_group_list_name: [] };
      this.studyListItems = this.studies.map((study) => ({
        value: study.id,
        text: study.name,
      }));
      this.newGroupDialog = true;
    },
    newUser() {
      this.userCreated = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role_id: null,
        role_name: "",
        study_group_id: null,
        study_group_name: "",
        is_active: false,
      };
      this.roleListItems = roleManager.roleList().map((role) => ({
        value: role.id,
        text: role.text,
      }));
      this.newUserDialog = true;
    },
    createCountry() {
      this.$store.commit('setLoading', true);
      this.userServices
        .createCountry(this.countryCreated)
        .then((response) => {
          this.countries.push(response.data);
          this.newCountryDialog = false;
          this.countryCreated = {};
          toast("Country created successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error creating country.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
    },
    getFolderStructure() {
      return this.$refs.folderTree.getItems();
    },
    createStudy() {
      if (this.studyFormValid) {
        this.$store.commit('setLoading', true);
        this.userServices.createStudy(this.studyCreated)
          .then((response) => {
            this.studies.push(response.data);
            toast("Study created successfully.", {
              type: "success",
              transition: "slide",
              dangerouslyHTMLString: true
            });
            this.step = 1;
            this.newStudyDialog = false;
          })
          .catch((error) => {
            console.log(error);
            toast("Error creating study.", {
              type: "error",
              transition: "slide",
              dangerouslyHTMLString: true
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.$store.commit('setLoading', false);
            }, 1700);
          });
      }
    },
    createGroup() {
      if (this.groupFormValid) {
        this.$store.commit('setLoading', true);
        this.userServices
          .createGroup(this.groupCreated)
          .then((response) => {
            this.groups.push(response.data);
            this.groupListItems.push({
              value: response.data.id,
              text: response.data.name,
            });
            this.newGroupDialog = false;
            this.groupCreated = { name: "", groupId: null, studyId: null, regionId: null, visible_group_list: [], visible_group_list_name: [] };
            toast("Group created successfully.", {
              type: "success",
              transition: "slide",
              dangerouslyHTMLString: true
            });
          })
          .catch((error) => {
            console.log(error);
            toast("Error creating group.", {
              type: "error",
              transition: "slide",
              dangerouslyHTMLString: true
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.$store.commit('setLoading', false);
            }, 1700);
          });
      }
    },
    createUser() {
      this.$store.commit('setLoading', true);
      this.userServices
        .createUser(this.userCreated)
        .then((response) => {
          const user = {
            ...response.data,
            study_group_id: response.data.study_group.id,
            study_group_name: response.data.study_group.name,
            role_id: roleManager.roleList().find(role => role.value == response.data.role).id,
            role_name: roleManager.roleList().find(role => role.value == response.data.role).text,
          };
          this.users.push(user);
          this.newUserDialog = false;
          this.userCreated = {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role_id: null,
            role_name: "",
            study_group_id: null,
            study_group_name: "",
            is_active: false,
          };
          toast("User created successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error creating the user.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
    },
    async getCountries() {
      this.$store.commit('setLoading', true);
      const response = await this.userServices.getCountries();
      /* eslint-disable no-param-reassign */
      this.countries = response.data || [];
      if (this.countries.length > 0) {
        /* eslint-enable no-param-reassign */
        this.countries.sort((a, b) => {
          const first_name = a.name.toLowerCase();
          const secondName = b.name.toLowerCase();
          return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
        });
      }
      setTimeout(() => {
        this.$store.commit('setLoading', false);
      }, 1700);
    },
    async getRegion() {
      this.$store.commit('setLoading', true);
      const response = await this.userServices.getRegion();
      if (response.data.length > 0) {
        this.regions = response.data;
        this.regionListItems = this.regions.map((region) => ({
          value: region.id,
          text: region.region_description,
        }));
      }
      setTimeout(() => {
        this.$store.commit('setLoading', false);
      }, 1700);
    },
    async getStudies() {
      try {
        this.$store.commit('setLoading', true);
        const response = await this.userServices.getStudies();
        /* eslint-disable no-param-reassign */
        this.studies = response.data || [];
        console.log(JSON.stringify(this.studies));
        if (this.studies.length > 0) {
          /* eslint-enable no-param-reassign */
          this.studies.sort((a, b) => {
            const first_name = a.name.toLowerCase();
            const secondName = b.name.toLowerCase();
            return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
          });
        }
        setTimeout(() => {
          this.$store.commit('setLoading', false);
        }, 1700);
      } catch (error) {
        console.error('Failed to fetch studies:', error);
        this.studies = [];
        setTimeout(() => {
          this.$store.commit('setLoading', false);
        }, 1700);
      }
    },
    async getGroups() {
      this.$store.commit('setLoading', true);
      const response = await this.userServices.getGroups();
      /* eslint-disable no-param-reassign */
      this.groups = response.data || [];

      if (this.groups.length > 0) {
        this.groupListItems = this.groups.map((group) => ({
          value: group.id,
          text: group.name,
        }));
        /* eslint-enable no-param-reassign */
        this.groups.sort((a, b) => {
          const first_name = a.name.toLowerCase();
          const secondName = b.name.toLowerCase();
          return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
        });
      }
      setTimeout(() => {
        this.$store.commit('setLoading', false);
      }, 1700);
    },
    async getUsers() {
      this.$store.commit('setLoading', true);
      const response = await this.userServices.getUsers();
      this.users = response.data.map((user) => ({
        ...user,
        role_id: this.roleListItems.find(role => role.text.toLowerCase() === user.role).value,
        role_name: this.roleListItems.find(role => role.text.toLowerCase() === user.role).text,
        study_group_id: user.study_group ? user.study_group.id : null,
        study_group_name: user.study_group ? user.study_group.name : null,
      }));
      /* eslint-enable no-param-reassign */
      this.users.sort((a, b) => {
        const first_name = a.last_name.toLowerCase();
        const secondName = b.last_name.toLowerCase();
        // eslint-disable-next-line
        return first_name < secondName ? -1 : first_name > secondName ? 1 : 0;
      });
      setTimeout(() => {
        this.$store.commit('setLoading', false);
      }, 1700);
    },
    /** Show delete country.
     * @param {Object} country Selected country object
     */
    confirmDeleteCountryDialog(country) {
      this.currentCountry = { ...country };
      this.deleteCountryDialog = true;
    },
    /** Show delete study.
     * @param {Object} study Selected user object
     */
    confirmDeleteStudyDialog(study) {
      this.currentStudy = { ...study };
      this.deleteStudyDialog = true;
    },
    /** Show delete group.
     * @param {Object} group Selected group object
     */
    confirmDeleteGroupDialog(group) {
      this.currentGroup = { ...group };
      this.deleteGroupDialog = true;
    },
    /** Show delete dialog.
     * @param {Object} user Selected user object
     */
    confirmDeleteUserDialog(user) {
      this.currentUser = { ...user };
      this.deleteUserDialog = true;
    },
    /** Delete country.
     * @param {int} countryId Country id to delete
     */
    deleteCountry(countryId) {
      this.countries = this.countries.filter(
        (country) => country.id !== countryId
      );
      this.error = "Country deleted";
      this.$store.commit('setLoading', true);
      this.userServices
        .deleteCountry(countryId)
        .then(() => {
          this.error = "Country deleted";
          this.countries = this.countries.filter(
            (country) => country.id !== countryId
          );
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "Error deleting country.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteCountryDialog = false;
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
    },
    /** Delete study.
     * @param {int} studyId Study id to delete
     */
    deleteStudy(studyId) {
      this.$store.commit('setLoading', true);
      this.userServices
        .deleteStudy(studyId)
        .then(() => {
          this.error = "Study deleted";
          this.studies = this.studies.filter((study) => study.id !== studyId);
        })
        .catch((error) => {
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "Error deleting study.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteStudyDialog = false;
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
    },
    /** Delete user.
     * @param {int} groupId Group id to delete
     */
    deleteGroup(groupId) {
      this.$store.commit('setLoading', true);
      this.userServices
        .deleteGroup(groupId)
        .then(() => {
          this.error = "Group deleted";
          this.groups = this.groups.filter((group) => group.id !== groupId);
        })
        .catch((error) => {
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "Error deleting group.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteGroupDialog = false;
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
    },
    /** Delete user.
     * @param {int} userId User id to delete
     */
    deleteUser(userId) {
      this.$store.commit('setLoading', true);
      this.userServices
        .deleteUser(userId)
        .then(() => {
          this.error = "User deleted";
          this.users = this.users.filter((user) => user.id !== userId);
          toast("User deleted successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error.response && error.response.data && error.response.data.detail ? error.response.data.detail : "Error deleting user.";
          toast(errorMessage, {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          this.deleteUserDialog = false;
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
    },
    /** Show update study dialog
     * @param {Object} study Objectto update
     */
    updateStudy(study) {
      this.studySelected = { ...study };
      this.studySelected.study_countries = study.study_countries.map((id) =>
        Number(id)
      );

      this.countryListItems = this.countries.map((country) => ({
        value: country.id,
        text: country.name,
      }));
      this.updateStudyDialog = true;
    },
    /** Show update group dialog
     * @param {Object} group Objectto update
     */
    updateGroup(group) {
      this.groupSelected = {
        ...group,
        study: group.study,
      };
      this.studyListItems = this.studies.map((study) => ({
        value: study.id,
        text: study.name,
      }));
      this.updateGroupDialog = true;
    },
    /** Show update user dialog
     * @param {Object} user Objectto update
     */
    updateUser(user) {
      this.userSelected = {
        ...user,
        role_id: this.roleListItems.find(role => role.text.toLowerCase() === user.role).value,
        role_name: this.roleListItems.find(role => role.text.toLowerCase() === user.role).text,
        study_group_id: user.study_group.id,
        study_group_name: user.study_group.name,
      };
      this.updateUserDialog = true;
    },
    saveStudyChanges() {
      this.$store.commit('setLoading', true);
      this.userServices
        .updateStudy(this.studySelected.id, this.studySelected)
        .then(() => {
          const foundIndex = this.studies.findIndex(
            (study) => study.id === this.studySelected.id
          );
          this.studies.splice(foundIndex, 1, { ...this.studySelected });
          this.studySelected = {};
          this.updateStudyDialog = false;
          toast("Study updated successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error updating study.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
      this.updateStudyDialog = false;
    },
    saveGroupChanges() {
      this.$store.commit('setLoading', true);
      this.userServices
        .updateGroup(this.groupSelected.id, this.groupSelected)
        .then(() => {
          const foundIndex = this.groups.findIndex(
            (group) => group.id === this.groupSelected.id
          );
          this.groups.splice(foundIndex, 1, { ...this.groupSelected });
          this.groupSelected = { id: null, name: "", study: null, regionId: null, visible_group_list: [], visible_group_list_name: [] };
          this.updateStudyDialog = false;
          toast("Group updated successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error updating group.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
      this.updateGroupDialog = false;
    },
    saveDataUser() {
      const userSelectedData = {
        ...this.userSelected,
        role: (this.roleListItems.find(role => role.value === this.userSelected.role_id).text).toLowerCase(),
        study_group: this.userSelected.study_group_id
      };
      this.$store.commit('setLoading', true);
      this.userServices
        .updateUser(this.userSelected.id, userSelectedData)
        .then((response) => {
          const foundIndex = this.users.findIndex(
            (user) => user.id === this.userSelected.id
          );
          const user = {
            ...response.data,
            role_id: this.roleListItems.find(role => role.text.toLowerCase() === response.data.role).value,
            role_name: this.roleListItems.find(role => role.text.toLowerCase() === response.data.role).text,
            study_group_id: response.data.study_group.id,
            study_group_name: response.data.study_group.name,
          };
          this.users.splice(foundIndex, 1, { ...user });
          this.userSelected = {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role_id: null,
            role_name: "",
            study_group_id: null,
            study_group_name: null,
            is_active: false,
          };
          this.error = response.data.first_name;
          this.updateUserDialog = false;
          toast("User updated successfully.", {
            type: "success",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .catch((error) => {
          console.log(error);
          toast("Error updating user.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit('setLoading', false);
          }, 1700);
        });
      this.updateUserDialog = false;
    },
    updatePanels() {
      let newPanels = [0];

      if (!this.isStudiesPanelDisabled) {
        newPanels.push(1);
      }

      if (!this.isGroupsPanelDisabled) {
        newPanels.push(2);
      }

      if (!this.isUsersPanelDisabled) {
        newPanels.push(3);
      }

      this.panels = newPanels;
    },
    initialPanelStates() {
      this.isStudiesPanelDisabled = this.countries.length === 0;
      this.isGroupsPanelDisabled =
        this.countries.length === 0 || this.studies.length === 0;
      this.isUsersPanelDisabled =
        this.countries.length === 0 ||
        this.studies.length === 0 ||
        this.groups.length === 0;
      this.updatePanels();
    },
    getCountryNamesByIds(countryIds) {
      return countryIds
        .map(id => this.countries.find(country => country.id === id)?.name)
        .filter(name => name !== undefined)
        .join(', ');
    },
    getStudyNameById(studyId) {
      const study = this.studies.find(s => s.id === studyId);
      return study ? study.name : '';
    },
    getGroupNameById(groupId) {
      const group = this.groups.find(g => g.id === groupId);
      return group ? group.name : '';
    },
    getRegionDescriptionById(regionId) {
      const region = this.regions.find(r => r.id === regionId);
      return region ? region.region_description : '';
    },
    nextStep(next) {
      if (this.step === 1 && this.isValidStep1()) {
        next();
        this.step++;
      } else if (this.step === 2 && this.isValidStep2()) {
        next();
        this.step++;
      } else if (this.step === 3 && this.isValidStep3()) {
        this.createStudy();
      }
    },
    prevStep(prev) {
      console.log("prev");
      if (this.step > 1) {
        prev();
        this.step--;
      }
    },
    isValidStep1() {
      this.$refs.step1Form.validate();
      return this.studyCreated.name.trim().length > 0 &&
        this.studyCreated.countryId.length > 0 &&
        this.studyCreated.regionId != null;
    },

    isValidStep2() {
      return true;
    },

    completeStepper() {
      console.log(this.step);
      if (this.step === 3 && this.isValidStep3()) {
        this.createStudy();
      }
    },
    isValidStep3() {
      return true;
    }
  },
};
</script>

<style scoped>
.actions-container {
  display: flex;
  justify-content: space-between;
}

.v-expansion-panel {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.expansion-content {
  padding: 2em 4em 5em 4em;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.expansion-title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.v-expansion-panel--default>.v-expansion-panel-header {
  transition: background-color 0.3s ease;
}

.v-expansion-panel--default>.v-expansion-panel-header:not(.v-expansion-panel-header--active):hover {
  background-color: #f4f4f4;
}

.title-icons {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 12px;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>