<template>
  <v-app>
    <v-main class="ma-0 mt-10">
      <div class="split left dl-bg-gradient">
        <v-col cols="8" offset-md="2">
          <v-row>
            <v-col><v-img class="" src="@/assets/img/logo_white.png" height="90px"></v-img></v-col>
          </v-row>
          <v-row style="margin-top: 30px;">
            <v-col class="text-center">
              <v-avatar color="white mx-auto" class="change-font" size="100">
                <v-icon size="50" color="secondary">mdi-email</v-icon>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <h3 class="text-white">Set new password</h3>
          </v-row>
          <v-row class="justify-center align-baseline mt-12">
            <v-text-field
              prepend-icon="mdi-email"
              v-model="email"
              label="E-mail"
              type="email"
              placeholder="Email"
              :rules="emailRules"
              required
              variant="underlined"
              color="white" base-color="white"
            ></v-text-field>
          </v-row>
          <v-row class="justify-center align-baseline">
            <v-text-field
              prepend-icon="mdi-lock"
              v-model="oldPassword"
              :type="showOldPassword ? 'text' : 'password'"
              label="Old password*"
              class="form-input"
              placeholder="Password"
              required
              @click:append="showOldPassword = !showOldPassword"
              :append-icon="showOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
              variant="underlined"
              color="white" base-color="white"
            ></v-text-field>
          </v-row>
          <v-row class="justify-center align-baseline">
            <v-text-field
              prepend-icon="mdi-lock"
              v-model="newPassword"
              :type="showNewPassword ? 'text' : 'password'"
              label="New password*"
              class="form-input"
              :rules="passwordRules"
              placeholder="Password"
              variant="underlined"
              color="white" base-color="white"
              required
              @click:append="showNewPassword = !showNewPassword"
              :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
            ></v-text-field>
          </v-row>
          <v-row class="justify-center align-baseline">
            <v-text-field
              prepend-icon="mdi-lock"
              v-model="repeatedPassword"
              :type="showRepeatedPassword ? 'text' : 'password'"
              label="Repeat new password*"
              class="form-input"
              placeholder="Repeat Password"
              :rules="passwordMatchRule"
              variant="underlined"
              color="white" base-color="white"
              required
              @click:append="showRepeatedPassword = !showRepeatedPassword"
              :append-icon="showRepeatedPassword ? 'mdi-eye-off' : 'mdi-eye'">
            </v-text-field>
          </v-row>
          <v-row class="justify-center">
            <v-btn
              class="indigo--text mt-6"
              @click="savePassword"
              :disabled="!formValid"
              variant="outlined"
              rounded="lg"
              color="white">
              Change my password
            </v-btn>
          </v-row>
        </v-col>
      </div>
      <div class="split right">
        <v-row class="mt-8 pt-5">
          <v-col cols="8" offset-md="2">
            <v-list-item-title class="text-h6 mt-12">Password must contain:</v-list-item-title>
            <v-list-item>
              <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold</v-icon>
              {{ lengthRule }}
            </v-list-item><br/>
            <v-list-item>
              <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold</v-icon>
              {{ upperRule }}
            </v-list-item><br/>
            <v-list-item>
              <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold</v-icon>
              {{ lowerRule }}
            </v-list-item><br/>
            <v-list-item>
              <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold</v-icon>
              {{ numberRule }}
            </v-list-item><br/>
            <v-list-item>
              <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold</v-icon>
              {{ characterRule }}
            </v-list-item><br/>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { PasswordChecker } from "@/utils/passwordUtilities";
import { createCoreServices } from "../services/coreService";
import { createUserServices } from "../services/userService";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);
    return {
      userServices: userServicesInstance,
    };
  },
  data() {
    return {
      email: "",
      oldPassword: "",
      newPassword: "",
      repeatedPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showRepeatedPassword: false,
      error: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordLength: false,
      lengthRule: "At least 8 characters.",
      upperRule: "At least 1 upper case letter (A..Z)",
      lowerRule: "At least 1 lower case letter (a..z)",
      numberRule: "At least 1 number (0...9)",
      characterRule: "At least 1 special character (/..-)",
      colorLength: "grey",
      colorUpper: "grey",
      colorLower: "grey",
      colorNumber: "grey",
      colorCharacter: "grey",
    };
  },
  created() {
    if (this.$route.params.token !== undefined) {
      this.token = this.$route.params.token;
      document.cookie = `csrftoken=${this.token}`;
    }
  },
  computed: {
    passwordRules() {
      return [this.passwordStrong, ...this.passwordDifferentRule];
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    passwordMatchRule() {
      return [
        () =>
          this.newPassword === this.repeatedPassword ||
          "Passwords do not match",
      ];
    },
    passwordDifferentRule() {
      return [
        () =>
          this.oldPassword !== this.newPassword ||
          "The new password must be different from the old one",
      ];
    },
    formValid() {
      return (
        this.passwordStrong() &&
        this.newPassword === this.repeatedPassword &&
        this.oldPassword !== this.newPassword &&
        this.email !== "" &&
        this.oldPassword !== ""
      );
    },
  },
  methods: {
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.newPassword);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.newPassword);
      this.colorLength = result ? "green" : "";
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.newPassword);
      this.colorUpper = result ? "green" : "";
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.newPassword);
      this.colorLower = result ? "green" : "";
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.newPassword);
      this.colorNumber = result ? "green" : "";
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.newPassword);
      this.colorCharacter = result ? "green" : "";
      return result;
    },
    savePassword() {
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("email", this.email);
      formData.append("old_password", this.oldPassword);
      formData.append("new_password", this.newPassword);
      try {
        this.userServices
          .updateExpiredPassword(formData)
          .then((response) => {
            this.auxSavePassword(response);
          })
          .catch((errorMessage) => {
            console.log(JSON.stringify(errorMessage));
            this.$store.commit("setLoading", false);
            toast(`Error updating the password: ${errorMessage}`, {
              type: "error",
              transition: "slide",
              dangerouslyHTMLString: true,
            });
          });
      } catch (error) {
        console.log(error);
        toast(`Unknown error: ${error}`, {
          type: "success",
          transition: "slide",
          dangerouslyHTMLString: true,
          delay: 3000,
        });
      }
    },
    auxSavePassword(response) {
      if (response.status === 200) {
        this.email = "";
        this.oldPassword = "";
        this.newPassword = "";
        this.repeatedPassword = "";
        toast("Password uploaded successfully.", {
          type: "success",
          transition: "slide",
          dangerouslyHTMLString: true,
          delay: 3000,
        });
        this.$store.commit("setLoading", false);
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      } else {
        toast(`Error updating the password: ${response.messages[0].message}`, {
          type: "error",
          transition: "slide",
          dangerouslyHTMLString: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.form-forgot-password {
  width: auto !important;
}

.div-avatar-style {
  margin: 1em 0;
  width: 100% !important;
  display: flex;
}

.change-font {
  margin: auto !important;
}

.forgot-pass-title {
  font-size: 32px;
  margin-bottom: 2em !important;
}

.forgot-pass-colstyle {
  text-align: center !important;
  align-items: center !important;
}

.muted-text {
  font-size: 18px;
  color: black;
}

.signin-text {
  padding-top: 1em;
  text-decoration: none;
}

.reset-pass-btn {
  margin-top: 20em;
}

.color--white {
  color: white;
}
.left {
    left: 0;
}
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.right {
  right: 0;
  background-color: white;
}
</style>
