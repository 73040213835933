<template>
  <v-card class="mx-auto my-0 py-0 card-style">
    <v-layout>
      <v-app-bar color="white">
        <template v-slot:image>
          <v-img color="white"></v-img>
        </template>
        <template v-slot:prepend>
          <v-app-bar-nav-icon color="primary" @click="$emit('toggle-drawer')"></v-app-bar-nav-icon>
        </template>
        <v-app-bar-title class="datalake-title">
          <v-img src="@/assets/img/logo_color.png" width="130"></v-img>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <span v-if="userName" class="datalake-title mr-5 text-secondary">{{ userName }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" to="/home/userProfile" class="appbar-style mx-1" plain v-bind="props">
              <v-icon class="icons-style">mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <span>Perfil de Usuario</span>
        </v-tooltip>
        <v-tooltip bottom v-if="uploadEnable">
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" class="appbar-style mx-1" to="/document/uploadFiles" plain v-bind="props">
              <v-icon class="icons-style">mdi-upload</v-icon>
            </v-btn>
          </template>
          <span>Subir Archivo</span>
        </v-tooltip>
        <v-tooltip bottom v-if="verifierEnable">
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" class="appbar-style mx-1" to="/document/validation" plain v-bind="props">
              <v-icon class="icons-style">mdi-shield-check</v-icon>
            </v-btn>
          </template>
          <span>Verificar Archivo</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn v-if="inboxNotifications > 0" fab dark color="primary" to='/inbox' plain class="appbar-style mx-1" v-bind="props">
              <v-badge color="red accent-5" overlap>
                <span slot="badge">{{ inboxNotifications }}</span>
                <v-icon class="icons-style">mdi-email</v-icon>
              </v-badge>
            </v-btn>
            <v-btn v-else fab dark color="primary" to='/inbox' plain class="appbar-style mx-1" v-bind="props">
              <v-icon class="icons-style">mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Notificaciones</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn fab dark color="primary" @click="logout" v-bind="props">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Cerrar Sesión</span>
        </v-tooltip>
      </v-app-bar>
      <v-main>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { useRouter } from 'vue-router';
import { createCoreServices } from '../../services/coreService';
import { createLogoutServices } from '../../services/logoutService';
import { mapGetters } from 'vuex';
import { roleManager } from '@/store/roleManager';
import { removeCookies } from '../../utils/cookies';


export default {
  name: 'AppHeader',
  components: {},
  setup() {
    const router = useRouter();
    const coreServices = createCoreServices();
    const logoutServices = createLogoutServices(coreServices);

    const logout = () => {
      logoutServices.logout()
        .then(() => {
          removeCookies();
          router.push('/');
        })
        .catch(err => console.error(err));
    };

    return { logout };
  },
  data() {
    return {
      uploadEnable: false,
      verifierEnable: false,
      dialogFeedback: false,
      register_name: ' ',
      register_last_name: null,
      inboxNotifications: 0,
      feedback: '',
      loading: false,
      error: '',
      typeAlert: 'error',
      alert: false,
    };
  },
  mounted() {
    this.checkUserData();
  },
  methods: {
    checkUserData() {
      this.uploadEnable = roleManager.isUploader();
      this.verifierEnable = roleManager.isVerifier();
    }
  },
  computed: {
    ...mapGetters(['user']),
    userName() {
      return this.user ? `${this.user.first_name}` : '';
    },
  },
};
</script>

<style scoped>
  .card-style {
      border-radius: 0;
      margin-top: 0 !important;
  }
  .registered-user {
    pointer-events: none;
  }
  .upload-input-feedback {
    margin: 0;
    padding: 0;
  }
  .v-card__subtitle {
    padding: 0;
  }
  .subtitle-description {
    padding-bottom: 16px !important;
  }
</style>