<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card elevation="2">
          <v-card-title class="d-flex align-center">
            <span class="title-icons mdi mdi-file-multiple text-primary"></span>
            <div class="title ml-2 text-primary">Document Trail</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="p-0 mx-10">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" variant="underlined" color="primary"
                class="search-field"></v-text-field>
            <v-data-table :headers="headers" :items="documents" :search="search">
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.created_timestamp="{ item }">
                <span v-if="item.created_timestamp">{{ item.created_timestamp }}</span>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template v-slot:item.expiration_date="{ item }">
                <span>{{ item.expiration_date }}</span>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.status_name="{ item }">
                <data-status-label :status_name="item.status_name"></data-status-label>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { createCoreServices } from '../services/coreService';
import { createDocumentServices } from '../services/documentService';
import DataStatusLabel from "@/components/generic/DataStatusLabel.vue";
import { useStore } from 'vuex';
import { toast } from "vue3-toastify";

export default {
  name: 'AuditDocuments',
  components: {
    DataStatusLabel,
  },
  setup() {
    const coreServicesInstance = createCoreServices();
    const documentServices = createDocumentServices(coreServicesInstance);
    const store = useStore();

    const search = ref('');
    const documents = ref([]);
    
    const headers = ref(
      [
        { title: 'ID', key: 'id' },
        { title: 'Name', key: 'name' },
        { title: 'Title', key: 'title' },
        { title: 'Status', key: 'status_name' },
        { title: 'Event description', key: 'event_description' },
        { title: 'Version', key: 'version' },
        { title: 'Created Timestamp', key: 'timestamp' },
        { title: 'Document Date', key: 'document_date' },
        { title: 'Expiration Date', key: 'expiration_date' },
        { title: 'Applicant', key: 'applicant' },
        { title: 'Last User', key: 'last_user' },
        { title: 'Study', key: 'study_name' },
        { title: 'Study Group', key: 'study_group_name' },
        { title: 'Study Country', key: 'study_country_name' },
        { title: 'Folder', key: 'folders_path_name' },
        { title: 'Commentary', key: 'commentary' },
      ]
    );
    const getDocumentHistory = async () => {
      try {
        store.commit('setLoading', true);
        const response = await documentServices.getDocumentHistory();
        // console.log(JSON.stringify(response));
        documents.value = response.data;
      } catch (error) {
        console.error(error);
        toast("Documents data is undefined.", {
          type: "error",
          transition: "slide",
          dangerouslyHTMLString: true
        });
      } finally {
        setTimeout(() => {
          store.commit('setLoading', false);
        }, 500);
      }
    };

    onMounted(async () => {
      await getDocumentHistory();
    });

    return {
      search,
      headers,
      documents,
    };
  },
};
</script>

<style scoped>
.title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.title-icons {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: 35px;
  margin-top: 20px;
}

.search-field {
  flex-grow: 0;
  width: 70%; 
  margin: 30px 10px;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
