export function createStudyGroupServices(coreServices) {
    return {
        getStudyGroups() {
            let url = `${process.env.VUE_APP_BASE_URL}/api/study-groups`;
            return coreServices.get(url);
        },
        createStudyGroup(data) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/`;
            return coreServices.post(url, data);
        },
        getStudyGroupById(groupId) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${groupId}/`;
            return coreServices.get(url);
        },
        updateStudyGroup(groupId, data) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${groupId}/`;
            return coreServices.put(url, data);
        },
        deleteStudyGroup(groupId) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${groupId}/`;
            return coreServices.delete(url);
        },
    };
}
