<template>
  <v-row class="pa-4" justify="space-between">
    <v-col v-if="left"></v-col>
    <v-col cols="9" class="ml-6">
      <div v-if="upload">
        <h3>Select a folder from tree to store the document</h3>
        <v-treeview activatable="true" selectable="true" v-model:selected="selection"
          select-strategy='single-independent' :items="items" item-title="name" item-value="id" return-object>
          <template v-slot:prepend="{ item }">
            <v-icon v-if="item.selected" aria-hidden="true" class="text-primary">mdi mdi-checkbox-marked-outline</v-icon>
          </template>
        </v-treeview>
      </div>
      <div v-else>
        <v-treeview :items="items" item-title="name" item-value="id" color="" density="compact" activatable
          open-on-click transition :open-all="openAll">
          <template v-slot:prepend="{ item }">
            <v-icon>{{ item.contains_files ? 'mdi-file' : 'mdi-folder' }}</v-icon>
          </template>
          <template v-slot:append="{ item }" v-if="editable">
            <v-icon small @click.stop="showCreateDialog(item)">mdi-folder-plus</v-icon>
            <v-icon small @click.stop="showEditDialog(item)">mdi-pencil</v-icon>
            <v-icon small @click.stop="showDeleteDialog(item)">mdi-delete</v-icon>
          </template>
        </v-treeview>
        <v-icon v-if="!openAll" class="mt-2 left-align-btn" @click="showCreateDialog(null)">mdi-folder-plus</v-icon>
      </div>
    </v-col>
    <v-col></v-col>
  </v-row>
  <v-dialog v-model="dialogCreate" max-width="900px">
    <v-card>
      <v-card-title class="text-h5">Create a new folder</v-card-title>
      <v-card-text>
        <v-text-field v-model="newFolderName" label="Folder Name" :rules="nameRules"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeCreateDialog">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="createFolder">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogEdit" max-width="900px">
    <v-card>
      <v-card-title class="text-h5">Edit folder name</v-card-title>
      <v-card-text>
        <v-text-field v-model="editedFolderName" label="New Folder Name" :rules="nameRules"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeEditDialog">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="updateFolder">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogDelete" max-width="900px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this folder?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
        <v-btn color="red darken-1" text @click="deleteFolder">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { VTreeview } from 'vuetify/labs/VTreeview';
import { createCoreServices } from '@/services/coreService';
import { createFolderService } from '@/services/folderService';
import { createStudyServices } from '@/services/studyService';
import { createUserServices } from '@/services/userService';

import { createStudyGroupServices } from '@/services/studyGroupService';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";


export default {
  components: {
    VTreeview
  },
  props: {
    upload: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    opened: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const studyGroupId = ref(null);

    const selection = ref([]);
    const folderIdSelected = ref([]);

    const items = ref([]);
    const openAll = ref([]);

    const dialogCreate = ref(false);
    const dialogEdit = ref(false);
    const dialogDelete = ref(false);
    const currentItem = ref(null);
    const newFolderName = ref('');
    const editedFolderName = ref('');
    const nameRules = [v => !!v || 'Name is required'];
    const refreshKey = ref(0);

    const coreServices = createCoreServices();
    const folderService = createFolderService(coreServices);
    const userService = createUserServices(coreServices);
    const studyService = createStudyServices(coreServices);
    const studyGroupService = createStudyGroupServices(coreServices);

    const fetchRootFolders = async () => {
      try {
        const response = await folderService.getGroupFolders(studyGroupId.value);
        const uniqueFolders = response.data.filter((folder, index, self) =>
          index === self.findIndex((f) => f.id === folder.id)
        );
        items.value = uniqueFolders.map(folder => ({
          ...folder,
          children: folder.children || [],
          selected: false
        }));
        openAll.value = props.opened;
      } catch (error) {
        console.error('Failed to fetch root folders:', error);
      }
    };

    const createFolder = async () => {
      if (newFolderName.value) {
        try {
          const parentId = currentItem.value ? currentItem.value.id : null;
          const newFolder = await folderService.createFolder(studyGroupId.value, {
            name: newFolderName.value,
            parent: parentId
          });
          if (currentItem.value) {
            if (!currentItem.value.children) {
              currentItem.value.children = [];
            }
            currentItem.value.children.push(newFolder);
          }
          newFolderName.value = '';
          dialogCreate.value = false;
          fetchRootFolders().then(() => {
            refreshTreeView()
          });

        } catch (error) {
          console.error('Error creating folder:', error);
        }
      }
    };

    const updateFolder = async () => {
      if (editedFolderName.value && currentItem.value && editedFolderName.value !== currentItem.value.name) {
        try {
          const parentId = currentItem.value.parent;
          await folderService.updateFolder(studyGroupId.value, currentItem.value.id, {
            name: editedFolderName.value,
            parent: parentId
          });
          currentItem.value.name = editedFolderName.value;
          editedFolderName.value = '';
          dialogEdit.value = false;
          fetchRootFolders().then(() => {
            refreshTreeView()
          });
        } catch (error) {
          console.error('Error updating folder:', error);
        }
      }
    };

    const deleteFolder = async () => {
      if (currentItem.value && (!currentItem.value.children || currentItem.value.children.length === 0)) {
        try {
          await folderService.deleteFolder(studyGroupId.value, currentItem.value.id);
          removeFolderFromTree(items.value, currentItem.value.id);
          dialogDelete.value = false;
          fetchRootFolders().then(() => {
            refreshTreeView()
          });
        } catch (error) {
          console.error('Error deleting folder:', error);
        }
      } else {
        toast("Cannot delete a folder with children.", {
          "type": "error",
          "transition": "slide",
          "dangerouslyHTMLString": true
        });
        dialogDelete.value = false;
      }
    };

    // Avoid bug selecting more than one node
    watch(selection, (newVal) => {
      const selectedItem = newVal[0];
      if (!selectedItem) {
        console.error('El elemento seleccionado no tiene un ID válido.');
        return;
      }
      emit('folder-selected', selectedItem);
      folderIdSelected.value = selectedItem;
      const updateSelectionStatus = (items) => {
        items.forEach(item => {
          if (item && item.id) {
            item.selected = (item.id === selectedItem);
            if (item.children && item.children.length > 0) {
              updateSelectionStatus(item.children);
            }
          }
        });
      };
      updateSelectionStatus(items.value);
    });

    onMounted(() => {
      if (props.upload) {
        userService.checkUserData()
          .then((response) => {
            if (response.data.study_group !== undefined) {
              studyGroupId.value = response.data.study_group.id;
              fetchRootFolders();
            }
          });

      } else {
        const studyName = store.getters.study;
        studyService.getStudiesList()
          .then((response) => {
            if (response.data.length > 0) {
              const study = response.data.find(study => study.name === studyName);
              if (study !== undefined) {
                studyGroupService.getStudyGroups()
                  .then((response) => {
                    if (response.data.length > 0) {
                      const studyGroup = response.data.find(studyGroup => studyGroup.study === study.id);
                      studyGroupId.value = studyGroup.id;
                      fetchRootFolders();
                    }
                  });
              }
            }
          })
      }
    });

    function refreshTreeView() {
      refreshKey.value++;
    }

    function removeFolderFromTree(folders, folderId) {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === folderId) {
          folders.splice(i, 1);
          return true;
        }
        if (folders[i].children && folders[i].children.length) {
          if (removeFolderFromTree(folders[i].children, folderId)) {
            return true;
          }
        }
      }
      return false;
    }

    function showCreateDialog(item) {
      currentItem.value = item;
      newFolderName.value = '';
      dialogCreate.value = true;
    }

    function showEditDialog(item) {
      currentItem.value = item;
      editedFolderName.value = item.name;
      dialogEdit.value = true;
    }

    function showDeleteDialog(item) {
      currentItem.value = item;
      dialogDelete.value = true;
    }

    function closeCreateDialog() {
      dialogCreate.value = false;
    }

    function closeEditDialog() {
      dialogEdit.value = false;
    }

    function closeDeleteDialog() {
      dialogDelete.value = false;
    }

    return {
      items, openAll, dialogCreate, dialogEdit, dialogDelete, showCreateDialog, showEditDialog, showDeleteDialog,
      closeCreateDialog, closeEditDialog, closeDeleteDialog, currentItem, newFolderName, editedFolderName, nameRules,
      createFolder, updateFolder, deleteFolder, selection, folderIdSelected
    };
  }
};
</script>

<style scoped>
.v-treeview .v-treeview-node__content {
  align-items: center;
}

.v-icon {
  margin-right: 5px;
}

.left-align-btn {
  margin-left: 2.1em;
  display: flex;
  justify-content: flex-start;
}
</style>
