<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card elevation="2">
          <v-card-title class="d-flex align-center">
            <span class="title-icons mdi mdi-file-multiple text-primary"></span>
            <div class="title ml-2 text-primary">Documents</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="p-0 mx-10">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" variant="underlined" color="primary"
              class="search-field"></v-text-field>
            <v-data-table :headers="headers" :items="documentsFiltered" :search="search" multi-sort>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.id="{ item }">
                <div @click="openPdfDialog(item.id, item.name)" class="cursor-pointer">{{ item.id }}</div>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.name="{ item }">
                <div class="cursor-pointer" @click="openPdfDialog(item.id)">{{ item.name }}</div>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.created_timestamp="{ item }">{{ formatDate(item.created_timestamp) }}</template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.status="{ item }">
                <data-status-label :status_name="item.status"></data-status-label>
              </template>
            </v-data-table>
          </v-card-text>
          <v-dialog v-model="showPdfDialog">
            <v-card class="pdf-card">
              <v-card-title class="pdf-card-title" style="overflow: hidden;">
                <v-btn icon class="pdf-btn" @click="closePdfDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pdf-card-text">
                <div id="pdf-wrapper">
                  <PdfViewer :files="selectedPdfFile"></PdfViewer>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import DataStatusLabel from "@/components/generic/DataStatusLabel.vue";
import PdfViewer from "@/components/PdfViewer.vue";
import { createCoreServices } from '../services/coreService';
import { createDocumentServices } from '../services/documentService';
import { toast } from "vue3-toastify";

export default {
  name: "DocumentView",
  components: {
    DataStatusLabel,
    PdfViewer
  },
  setup() {
    const coreServicesInstance = createCoreServices();
    const documentServicesInstance = createDocumentServices(coreServicesInstance);
    const store = useStore();

    const showPdfDialog = ref(false);
    const search = ref("");
    const error = ref("");
    const documents = ref([]);
    const documentsFiltered = ref([]);
    const files = ref([]);
    const selectedPdfFile = ref([]);

    const headers = [
      { title: "File ID", key: "id" },
      { title: "Name", key: "name", align: "start" },
      { title: "Status", key: "status" },
      { title: "Study", key: "study.name" },
      { title: "Study group", key: "study_group.name" },
      { title: "Study country", key: "study_country.name" },
      { title: "Upload Date", key: "created_timestamp" },
      { title: "Document Date", key: "document_date" },
      { title: "Expiration Date", key: "expiration_date" },
    ];

    const getData = () => {
      store.commit('setLoading', true);
      documentServicesInstance.getDocumentData()
        .then((response) => {
          documents.value = response.data;
          documentsFiltered.value = documents.value;
        }).catch((/*err*/) => {
          // console.error(err);
          error.value = "Error loading documents";
          toast("Error loading documents.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            store.commit('setLoading', false);
          }, 500);
        });
    };

    const openPdfDialog = (documentId, name) => {
      store.commit('setLoading', true);
      documentServicesInstance.getDocumentSigned(documentId)
      .then((documentData) => {
        if (documentData && documentData.data) {
          selectedPdfFile.value = [{
            url: documentData.data.url,
            title: name,
          }];
          showPdfDialog.value = true;
        } else {
          console.error('Document data is undefined.');
          toast("Document data is undefined.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
        console.error('Error fetching document:', error);
        toast("Error fetching document: " + error, {
          type: "error",
          transition: "slide",
          dangerouslyHTMLString: true
        });
      })
      .finally(() => {
        setTimeout(() => {
          store.commit('setLoading', false);
        }, 500);
      });
    };

    const closePdfDialog = () => {
      showPdfDialog.value = false;
    };

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };

    onMounted(getData);


    return {
      showPdfDialog,
      search,
      error,
      documentsFiltered,
      headers,
      files,
      openPdfDialog,
      closePdfDialog,
      formatDate,
      selectedPdfFile
    };
  },
  methods: {
    afterCreatedHandler(pdfApp) {
      pdfApp.isViewerEmbedded = true;
    },
    async openHandler(pdfApp) {
      this.info = [];
      this.$store.commit('setLoading', true);
      const info = await pdfApp.pdfDocument
        .getMetadata()
        .catch(console.error.bind(console));
      if (!info) return;
      const props = Object.keys(info.info);
      props.forEach((prop) => {
        const obj = {
          name: prop,
          value: info.info[prop],
        };
        this.info.push(obj);
      })
      .finally(() => {
        setTimeout(() => {
          this.$store.commit('setLoading', false);
        }, 500);
      });
    },
    pagesRenderedHandler(pdfApp) {
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-height"));
    },
  },
};
</script>
<style scoped>
.title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.title-icons {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: 35px;
  margin-top: 20px;
}

.search-field {
  flex-grow: 0;
  width: 70%;
  margin: 30px 10px;
}

.documents-table {
  justify-content: center;
}

#pdf-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
}

.v-application .tab-router-link {
  text-decoration: none;
}

.v-application .tab-router-link:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.v-overlay__scrim {
  background: none !important;
}

.v-dialog {
  border-radius: 0 !important;
  max-width: 1200px;
}

.pdf-card {
  background: rgba(166, 185, 194, 0.35);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pdf-card-title {
  padding: 0;
  top: -20px;

}

.pdf-card-text {
  overflow: hidden;
  margin-bottom: 20px;
}

.pdf-btn {
  border-radius: 0;
  box-shadow: none;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
  background: rgba(0, 0, 0, 0);
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>