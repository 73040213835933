export function createFolderService(coreServices) {
  return {
    getGroupFolders(studyGroupId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${studyGroupId}/folders/`;
      return coreServices.get(url);
    },
    createFolder(studyGroupId, folderData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${studyGroupId}/folders/`;
      const header = coreServices.getRequestHeader();
      return coreServices.post(url, folderData, header);
    },
    getSingleFolder(studyGroupId, folderId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${studyGroupId}/folders/${folderId}/`;
      return coreServices.get(url);
    },
    updateFolder(studyGroupId, folderId, folderData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${studyGroupId}/folders/${folderId}/`;
      return coreServices.put(url, folderData);
    },
    deleteFolder(studyGroupId, folderId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${studyGroupId}/folders/${folderId}/`;
      return coreServices.delete(url);
    },
    getFolderPaths(studyGroupId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/${studyGroupId}/folders/paths/`;
      return coreServices.get(url);
    }
  };
}