<template>
  <div id="preview-div" v-if="filesEncoded.length > 0">
    <v-row>
      <v-col v-for="(file, index) in filesEncoded" :key="index" sm="6">
        <v-col class="pa-2">
          <!-- Elimina el espacio en el ID del canvas -->
          <canvas :id="'canvas-' + index" class="the-canvas"></canvas>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf.mjs';
GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.mjs';

export default {
  name: 'PdfViewer',
  props: {
    filesEncoded: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    if (this.filesEncoded.length > 0) {
      this.filesEncoded.forEach((file, index) => {
        this.getPdf(file, index);
      });
    }
  },
  methods: {
    async getPdf(file, index) {
      try {
        const loadingTask = getDocument({ data: atob(file) });
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        const canvas = document.getElementById(`canvas-${index}`);
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext);
      } catch (error) {
        console.error("Error loading PDF: ", error);
      }
    },
  },
};
</script>

<style scoped>
.the-canvas {
  border: 1px solid black;
  direction: ltr;
}
</style>
