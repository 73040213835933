export const environmentService = {
    getTermsAndConditionsUrl() {
      // TODO
      const environment = this.getEnvironment();
      if (environment === 'DEVELOP') {
        return 'https://';
      }
      return 'https://';
    },
    getEnvironment() {
      const url = process.env.VUE_APP_USER_API_URL;
      if (url.includes('localhost') || url.includes('dev')) {
        return 'DEVELOP';
      }
      return 'PRODUCTION';
    },
  };
  