<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card elevation="2">
          <v-row>
            <v-col cols="12" class="users-table">
              <v-card-title class="d-flex align-center">
                <span class="title-icons mdi mdi-account-multiple text-primary"></span>
                <div class="title ml-2 text-primary">Audit users</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="p-0 mx-10">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" variant="underlined" color="primary"
                  class="search-field"></v-text-field>
                <v-spacer></v-spacer>
                <v-data-table :headers="headers" :items="users" :search="search">
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.modification="{ item }">
                    <span v-if="item.modification === ''">-</span>
                    <span v-else>{{ item.modification }}</span>
                  </template>
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item.modification_timestamp="{ item }">
                    <span>{{ item.modification_timestamp.replace('T', ' ') }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
import { toast } from "vue3-toastify";

export default {
  name: 'AuditUsers',
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);
    const store = useStore();

    const search = ref('');
    const users = ref([]);
    const headers = [
      { title: 'User', key: 'user_email' },
      { title: 'Event description', key: 'event_description' },
      { title: 'Action', key: 'action' },
      { title: 'Modification', key: 'modification' },
      { title: 'Modifier', key: 'modifier_email' },
      { title: 'Last mod. timestamp', key: 'modification_timestamp' },
    ];

    async function getUserHistory() {
      try {
        store.commit('setLoading', true);
        const response = await userServicesInstance.getUserHistory();
        users.value = response.data;
      } catch (err) {
        console.error(err);
        toast("User data is undefined.", {
          type: "error",
          transition: "slide",
          dangerouslyHTMLString: true
        });
      } finally {
        setTimeout(() => {
          store.commit('setLoading', false);
        }, 500);
      }
    }

    onMounted(async () => {
      await getUserHistory();
    });

    return {
      search,
      users,
      headers,
    };
  },
};
</script>
<style scoped>
.title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.title-icons {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: 35px;
  margin-top: 20px;
}

.search-field {
  flex-grow: 0;
  width: 70%;
  margin: 30px 10px;
}

.users-table {
  justify-content: center;
}

.doc {
  margin-left: 50px;
  padding-top: 80px;
  padding-bottom: 40px;
}

.title_doc {
  margin-top: 20px;
  margin-left: 50px;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
