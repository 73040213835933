<template>
  <v-card class="mx-auto" variant="flat" max-width="300">
    <v-list>
      <v-list-item>
        <v-img class="mt-4" src="@/assets/img/logo_color.png" height="70" contain></v-img>
      </v-list-item>
    </v-list>
    <v-divider class="my-2"></v-divider>
    <v-list :lines="false" density="compact" nav>
      <v-list-item
        v-for="item in menuItems"
        :key="item.title" 
        :active="isRouteActive(item.path)"
        link
        :to="item.path"
        color="primary"
        variant="text"
        lines="two"
        density="compact"
        :border="true">
        <template v-slot:prepend>
          <v-icon :icon="item.icon"></v-icon>
        </template>
        <v-list-item-title class="custom-text-item-size">
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { roleManager } from '@/store/roleManager';
import { mapGetters } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      menuItems: [
        { text: 'HOME', path: '/home', icon: 'mdi-home' },
      ],
      particlesOptions: {
        particles: {
          number: {
            value: 80,
          },
          size: {
            value: 3,
          },
          move: {
            enable: true,
            speed: 3,
          },
          opacity: {
            value: 0.5,
          },
          line_linked: {
            enable: true,
            distance: 150,
          },
        },
      }

    };
  },
  mounted() {
    this.controlPanelTab();
  },
  methods: {
    controlPanelTab() {
      if (roleManager.isAdmin() || roleManager.isStaff()) {
        this.menuItems.push(
          { text: 'DOCUMENTS', path: '/document', icon: 'mdi-file-document' },
          { text: 'ADMIN', path: '/admin', icon: 'mdi-account-supervisor' },
          { text: 'USER TRAIL', path: '/audit-users', icon: 'mdi-account-clock' },
          { text: 'DOCUMENT TRAIL', path: '/audit-documents', icon: 'mdi-file-document' }
        );
      }
      if (roleManager.isUploader() || roleManager.isVerifier() || roleManager.isResearcher()) {
        this.menuItems.push(
          { text: 'DOCUMENTS', path: '/document', icon: 'mdi-file-document' },
        );
      }
    },
  },
  computed: {
    ...mapGetters(['activeRoute']),
    isRouteActive() {
      return (path) => this.activeRoute === path;
    }
  }
};
</script>

<style scoped lang="scss">
  .logo {
    margin-top: 0;
  }
  .align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-text-item-size {
    font-size: 1em;
    font-weight: 600;
  }
  .v-list.v-list--nav > a.v-list-item {
     &.v-list-item--active {
       border-color: var(--v-primary-primary);
     }
     &:not(.v-list-item--active) {
      border: none;
     }
  }
</style>
