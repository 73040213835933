<template>
  <div class="main-container">
    <div class="content">
      <v-container style="max-width: 1200px;">
        <div v-for="(file) in items" :key="file.id">
          <v-card :id="'file-' + file.id" outlined elevation="5" class="validation-card">
            <v-card-title>
              <span class="upload-title" @click="openPdfDialog(file.id, file.name)">
                {{ file.title }}
              </span>
              <v-btn icon class="ml-5 mb-3" @click="openPdfDialog(file.id, file.name)">
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
              <v-btn icon class="ml-3 mb-3" v-if="canDownloadPdf" @click="downloadPdf(file, file.name)">
                <v-icon size="18">mdi-download</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-file-input id="file" ref="file" name="fileToUpload" prepend-icon="mdi-cloud-upload"
                label="Upload New File" enctype="multipart/form-data" class="upload-input mt-3 mb-3" v-model="fileInput"
                @change="checkFile" @click:clear="resetValidationButton" variant="underlined" color="primary"
                :accept="Constants.ACCEPTED_FILE_EXTENSIONS.join(', ')">
                <template v-slot:selection>
                  <v-chip v-for="file in selectedFiles" :key="file.name" dark label small>{{ file.name }}</v-chip>
                </template>
              </v-file-input>
              <!-- <v-btn class="ma-2" color="secondary accent-4" text @click="submitUpload">
                <v-icon dark class="ml-2">mdi-cloud-upload</v-icon>
              </v-btn> -->
              <div><strong>Study Group:</strong> {{ file.study_group.name }}</div>
              <div><strong>Submitted by:</strong> {{ file.applicant.first_name }} {{ file.applicant.last_name }}</div>
              <div class="mb-10"><strong>Status:</strong> {{ file.status }}</div>
              <v-textarea class="mt-7" @input="updateItemValidity(file)" :rules="commentaryRules"
                v-model="file.commentary" label="Verifier Comments" variant="underlined" color="primary" auto-grow rows="3"></v-textarea>
              <v-select @update:modelValue="updateItemValidity(file)" v-model="file.newStatus" :rules="statusRules"
                :items="documentNewStatus" label="Select New Status" variant="underlined" color="primary" class="mt-3"></v-select>
            </v-card-text>
            <v-card-actions>
              <div class="actions-container">
                <v-btn class="update-btn px-4 py-2" variant="outlined" color="primary" :disabled="!file.isValid"
                  @click="updateDocumentStatus(file)">
                  <v-icon dark class="mr-3">mdi-file-restore</v-icon>
                  {{ validationButton }}
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
          <br />
        </div>
      </v-container>
      <v-dialog v-model="showPdfDialog">
        <v-card class="pdf-card">
          <v-card-title class="pdf-card-title" style="overflow: hidden;">
            <v-btn icon class="pdf-btn" @click="closePdfDialog" variant="outlined" color="primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pdf-card-text">
            <div id="pdf-wrapper">
              <PdfViewer :files="selectedPdfFile.value"></PdfViewer>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { createCoreServices } from '@/services/coreService';
import { createValidationServices } from '@/services/validationService';
import { createFileServices } from "../services/fileService";
import PdfViewer from "@/components/PdfViewer.vue";
import { roleManager } from '@/store/roleManager';
import { Constants } from "../store/constants";

export default {
  name: 'ValidationForm',
  components: {
    PdfViewer,
  },
  setup() {
    const files = ref([]);
    const validationButton = ref("Update Document");
    const fileInput = ref(null);
    const selectedFiles = ref([]);
    const commentaryRules = [v => !!v || 'Commentary is required'];
    const statusRules = [v => !!v || 'Status is required'];
    const selectedPdfFile = ref([]);

    const updateValidationButton = () => {
      if (fileInput.value) {
        validationButton.value = "Finish Document";
      } else {
        validationButton.value = "Update Document";
      }
    };

    const resetValidationButton = () => {
      validationButton.value = "Update Document";
    };

    return {
      commentaryRules,
      statusRules,
      files,
      validationButton,
      selectedFiles,
      fileInput,
      updateValidationButton,
      resetValidationButton,
      selectedPdfFile
    }
  },
  data() {
    return {
      items: [],
      documentNewStatus: ['approved', 'reevaluated'],
      validationsServices: null,
      showPdfDialog: false,
      fileServices: null,
      Constants,
    };
  },
  mounted() {
    this.coreServicesInstance = createCoreServices();
    this.fileServices = createFileServices(this.coreServicesInstance);
    this.validationsServices = createValidationServices(this.coreServicesInstance);
    this.getFilesToVerify();
  },
  computed: {
    canDownloadPdf() {
      return roleManager.isResearcher() || roleManager.isVerifier();
    },
  },
  methods: {
    getFilesToVerify() {
      this.validationsServices.getVerifyFiles()
        .then((response) => {
          this.items = response.data
            .filter((file) => file.status === 'raw')
            .map((file) => ({
              ...file,
              newStatus: this.documentNewStatus[1],
              commentary: file.commentary || '',
              isValid: file.commentary.trim(),
            }));
        }).catch((error) => {
          console.error('Error fetching documents:', error);
        });
    },
    updateDocumentStatus(file) {
      if (file.newStatus === 'approved') {
        this.processDocument(file.id);
      }
      else if (file.newStatus === 'reevaluated') {
        this.reevaluateDocument(file.id);
      }
    },
    updateItemValidity(file) {
      console.log(file.newStatus.trim());
      console.log(file.commentary.trim());
      file.isValid = file.newStatus.trim() && file.commentary.trim();
    },
    processDocument(documentId) {
      const commentary = this.items.find(item => item.id === documentId)?.commentary || '';
      this.validationsServices.processDocument(documentId, commentary)
        .then((response) => {
          console.log('Document processed successfully');
          console.log(JSON.stringify(response));
          this.filterItems(documentId);
        })
        .catch(error => {
          console.error('Error processing document:', error);
          console.log(error);
          toast("Error processing document. Please try again.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
    },
    reevaluateDocument(documentId) {
      const commentary = this.items.find(item => item.id === documentId)?.commentary || '';
      this.validationsServices.reevaluateDocument(documentId, commentary)
        .then((response) => {
          console.log(JSON.stringify(response));
          this.filterItems(documentId);
        })
        .catch(error => {
          console.error('Error setting document for reevaluation:', error);
          console.log(error);
          toast("Error setting document for reevaluation. Please try again.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        });
    },
    filterItems(id) {
      this.items = this.items.filter(
        (item) => item.id !== id
      );
    },
    openPdfDialog(documentId, name) {
      this.validationsServices.getDocumentSigned(documentId)
      .then((documentData) => {
        if (documentData && documentData.data) {
          this.selectedPdfFile = [{
            url: documentData.data.url,
            title: name,
          }];
          this.showPdfDialog = true;
        } else {
          console.error('Document data is undefined.');
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
        console.error('Error fetching document:', error);
        toast("Error fetching document: " + error, {
          type: "error",
          transition: "slide",
          dangerouslyHTMLString: true
        });
      });
    },
    async downloadPdf(file, name) {
      try {
        const response = await this.validationsServices.getDocumentSigned(file.id);
        if (response && response.data && response.data.url) {
          const pdfUrl = response.data.url;
          const fetchResponse = await fetch(pdfUrl, { mode: 'cors' });
          if (!fetchResponse.ok) {
            throw new Error('Network response was not ok');
          }
          const blob = await fetchResponse.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Document data is undefined or does not contain URL.');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        toast("Error fetching document: " + error, {
          type: "error",
          transition: "slide",
          dangerouslyHTMLString: true
        });
      }
    },
    closePdfDialog() {
      this.showPdfDialog = false;
    },
    submitUpload() {
      const archive = document.querySelector("#file");
      this.fileServices.replaceFile(archive)
        .then((results) => {
          results.forEach((res) => {
            console.log(JSON.stringify(res));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkFile(event) {
      this.selectedFiles = [...event.target.files];
      this.validateFiles();
    },
    validateFiles() {
      let fail = false;
      this.urls = [];
      this.base64Files = [];
      this.uploadButtonFail = this.selectedFiles.some((file) => {
        const ext = file.name.split(".").pop();
        if (!Constants.ACCEPTED_FILE_EXTENSIONS.includes(`.${ext}`)) {
          fail = true;
          return true;
        }
        return false;
      });
      if (!fail) {
        this.validationButton = "Finish Document";
        this.convertFilesToBase64();
      }
    },
    async convertFilesToBase64() {
      const promises = this.selectedFiles.map((file) =>
        this.fileToBase64(file)
      );
      const results = await Promise.all(promises);
      results.forEach((base64, index) => {
        const ext = this.selectedFiles[index].name.split(".").pop();
        if (ext === "pdf") {
          this.base64Files.push(base64);
        } else {
          this.urls.push(base64);
        }
      });
    },
    fileToBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result.split(",")[1];
          resolve(base64);
        };
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style scoped>
.content {
  padding: 0 2em;
  max-width: 1200px;
  margin: auto;
}

.validation-card {
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 2em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.v-card-title {
  font-weight: bold;
  font-size: 1.2em;
}

.upload-title {
  font-size: 1.2em;
  margin-bottom: 1em;
  cursor: pointer;
}

.v-card-text {
  padding: 20px;

}

.v-card-text>div {
  margin-bottom: 16px;
}

.actions-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.v-text-field.underlined::before,
.v-select::before {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.v-text-field.no-border,
.v-select {
  border-bottom: none;
}

.update-btn {
  background-color: rgb(var(--v-theme-secondary)) !important;
  color: #000 !important;
  margin: auto;
  padding: 6px 16px;
  text-transform: none;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  padding: 10px 25px;
  min-width: 150px;
  min-height: 40px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.update-btn .v-icon {
  line-height: 1;
}

.validation-row {
  padding: 0 2em;
}

.validation-file-title {
  font-size: 1.4em;
  font-weight: 400;
}

.validation-title-card {
  padding: 1em;
  background: rgba(0, 0, 0, 0)important;
}

.validation-toolbar {
  background: none;
}

.validation-toolbar-style {
  background: none;
}

.validation-card {
  padding: 3em;
}

.select-style {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 120px;
}

.save-style {
  width: 250px;
  margin-left: 0px;
  padding-left: 30px;
  margin-top: 6px;
}

.check-style {
  margin-left: 15px;
}

.alert-box {
  position: absolute;
  left: 70%;
  margin: 0 auto;
}

#pdf-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 650px;
}

.pdf-card {
  background: rgba(166, 185, 194, 0.35);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pdf-card-title {
  padding: 0;
  top: -20px;

}

.pdf-card-text {
  overflow: hidden;
  margin-bottom: 20px;
}

.pdf-btn {
  border-radius: 0;
  box-shadow: none;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
  background: rgba(0, 0, 0, 0);
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
