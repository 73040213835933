<template>
  <v-app>
    <v-main class="pa-4">
      <div>
        <v-alert v-if="error" type="error" transition="scale-transition">Invalid email address</v-alert>
        <div class="forgotPassword">
          <v-form class="form-forgot-password">
            <v-img class="text-center" alt="logo" src="@/assets/img/logo_color.png" width="150px"></v-img>
            <div class="div-avatar-style">
              <v-avatar color="primary" class="change-font" size="100">
                <v-icon size="50" color="white">mdi-key-variant</v-icon>
              </v-avatar>
            </div>
            <v-row>
              <v-col class="forgot-pass-colstyle" md="6" offset-md="3">
                <span class="forgot-pass-title">Forgot Your Password?</span>
                <v-text-field v-model="email" label="E-mail*" class="form-input pt-5" id="email" placeholder="Email"
                  prepend-icon="mdi-account" required  variant="underlined" color="primary"></v-text-field>
                <span class="muted-text">
                  Enter your account email address and we will send you a reset.
                </span>
                <div class="pt-5 center-text">
                  <v-btn variant="outlined" color="primary" rounded="lg" @click="resetPassword" :disabled="formValid">
                    Reset password
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class=" signin-text text-center">
            Already have login and password? <a class="signin-hyperlink" href="/login"> Sign in</a>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
export default {
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);

    return {
      userServices: userServicesInstance,
    };
  },
  data() {
    return {
      email: '',
      error: false,
    };
  },
  methods: {
    resetPassword() {
      const formData = new FormData();
      formData.append('email', this.email);
      this.userServices.forgotPassword(formData)
        .then(() => {
          this.$router.push('/checkEmail');
        })
        .catch(() => {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 5000);
        });
    },
  },
  computed: {
    formValid() {
      return !(this.email !== '');
    },
  },

};
</script>
<style scoped>
.form-forgot-password {
  width: auto !important;
}

.div-avatar-style {
  margin: 1em 0;
  width: 100% !important;
  display: flex;
}

.change-font {
  margin: auto !important;
}

.forgot-pass-title {
  font-size: 32px;
  margin-bottom: 20em !important;
}

.forgot-pass-colstyle {
  text-align: center !important;
  align-items: center !important;
}

.muted-text {
  font-size: 18px;
  color: black;
}

.signin-text {
  padding-top: 1em;
  text-decoration: none;
}

.reset-pass-btn {
  margin-top: 20em;
}

.color--white {
  color: white;
}
</style>