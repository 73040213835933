export function createFileServices(coreServices) {
  return {
    preClassification(data, documentId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/`;
      const header = coreServices.getRequestHeader();
      return coreServices.put(url, data, header);
    },
    getStudyCountryList() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-countries/`;
      return coreServices.get(url);
    },
    getStudyGroupList() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/`;
      return coreServices.get(url);
    },
    neededClassifyFiles() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/need-classify/`;
      return coreServices.get(url);
    },
    getStudies() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/studies/`;
      return coreServices.get(url);
    },
    getDocument(documentId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/`;
      return coreServices.get(url);
    },
  };
}