
export function createFileServices(coreServices) {
  return {
    getInboxData() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/files/get-inbox-data/`;
      return coreServices.get(url);
    },
    uploadFile(archive) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/`;
      const headers = coreServices.getMultipartRequestHeader();
      const responses = [];
      Array.from(archive.files).forEach((element) => {
        const data = new FormData();
        data.append('name', element.name);
        data.append('document_type', element.type);
        data.append('file_obj', element);
        responses.push(coreServices.post(url, data, headers));
      });
      return responses;
    },
    replaceFile(fileId, archive) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${fileId}/replace/`;
      const header = coreServices.getMultipartRequestHeader();
      const data = new FormData();
      data.append('name', archive.name);
      data.append('document_type', archive.type);
      data.append('file_obj', archive);
      return coreServices.put(url, data, header);
    },
    updateDocument(fileId, documentData) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${fileId}/`;
      const headers = coreServices.getJsonRequestHeader();
      return coreServices.put(url, documentData, headers);
    },
  };
}
