<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Grouped by year</v-card-title>
          <apexchart type="bar" :options="barChartOptions" :series="barChartOptions.series"></apexchart>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Lines</v-card-title>
          <apexchart type="line" :options="lineChartOptions" :series="lineChartOptions.series"></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="12" sm="6" md="4" v-for="item in cardItems" :key="item.title">
        <v-card>
          <v-img src="@/assets/img/logo_color.png" height="60px"></v-img>
          <v-card-title>{{ item.title }}</v-card-title>
          <v-card-text>{{ item.text }}</v-card-text>
          <v-card-actions>
            <v-btn text color="primary">Read more</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Details
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
              hide-details variant="underlined"></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="tableItems" :search="search" density="compact"></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';


export default {
  name: 'HomeView',
  data() {
    return {
      drawer: true,
      urgentAlerts: [],
      cardItems: [
        { title: 'Lorem ipsum',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent hendrerit ut erat id semper',
        image: 'url-a-imagen-2' },
        { title: 'Dolor sit amet',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent hendrerit ut erat id semper',
        image: 'url-a-imagen-2' },
        { title: 'Lorem ipsum dolor sit amet',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent hendrerit ut erat id semper',
        image: 'url-a-imagen-3' },
      ],
      headers: [
        { text: 'Header 1', value: 'value1' },
        { text: 'Header 2', value: 'value2' },
      ],
      tableItems: [
        { value1: 'Lorem', value2: 'Ipsum' },
      ],
      barChartOptions: {
        chart: {
          type: 'bar',
        },
        series: [{
          name: 'serie-1',
          data: [45, 55, 60, 49, 60, 70, 91]
        }],
        colors: ['#55c08b'],
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997]
        }
      },
      lineChartOptions: {
        chart: {
          type: 'line'
        },
        series: [{
          name: 'serie-1',
          data: [30, 40, 35, 50, 49, 60, 70, 91]
        }],
        colors: ['#55c08b'],
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997]
        }
      }
    };
  },
  setup() {
    const data = ref(null);
    const store = useStore();

    onMounted(async () => {
      try {
        const res = await fetch("https://jsonplaceholder.typicode.com/todos/1");
        data.value = await res.json();

        store.dispatch('setLoading', false);
        store.dispatch('setAppLoaded');
      } catch (e) {
        console.error(e);
        store.dispatch('setLoading', false);
      }
    });
    return {
      data,
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    onMenuItemClicked(item) {
      console.log('Menu item clicked:', item);
    }
  }
};
</script>

<style scoped>
.home-style-cards {
  flex: 1;
  margin: 10px;
  min-width: 300px;
  flex-wrap: wrap;
  padding: 16px 10px;
  height: 800px;
  justify-content: center;
  margin-top: 0 !important;
}

.card-title-style {
  margin: 10px;
}

::v-deep .v-data-table-header__content {
  font-weight: bold !important;
}
</style>
