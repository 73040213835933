
<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col class="text-center">
          <v-img alt="logo" src="@/assets/img/logo_color.png" width="180px"></v-img>
          <v-form class="form-forgot-password">
            <div class="text-center">
              <v-avatar color="primary" class="change-font" size="100">
                <v-icon size="50" color="white">mdi-email</v-icon>
              </v-avatar>
            </div>
            <v-row>
              <v-col class="text-center mt-6">
                <p class="text-h4 text-primary mb-6">Check your email</p>
                <p>We sent you a email with a new password.</p>
              </v-col>
            </v-row>
          </v-form>
          <div class="mt-6">
            Didn't receive an email?
            <a class="signin-hyperlink" href="/forgotPassword">Click to resend</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="forgotPassword">
      
    </div>
  </v-app>
</template>

<style scoped>
</style>