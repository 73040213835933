export const ROLES = {
    ADMIN: 'admin',
    UPLOADER: 'uploader',
    RESEARCH: 'research',
    VERIFIER: 'verifier',
    CLIENT: 'Client', // TODO 
    EDITOR: 'Editor', // TODO 
    APPROVER: 'Approver', // TODO 
    EDITORAPPROVER: 'EditorApprover', // TODO 
    STAFF: 'Admin' // TODO 
  };
  
  export default ROLES;
  