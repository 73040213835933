export function createValidationServices(coreServices) {
  return {
    getVerifyFiles() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/`;
      return coreServices.get(url);
    },
    getDocument(documentId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/`;
      return coreServices.get(url);
    },
    getDocumentSigned(documentId) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/document-url/`;
      return coreServices.get(url);
    },
    processDocument(documentId, commentary) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/process/`;
      const body = { commentary };
      return coreServices.patch(url, body, coreServices.getRequestHeader());
    },
    reevaluateDocument(documentId, commentary) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/revaluate/`;
      const body = { commentary };
      return coreServices.patch(url, body, coreServices.getRequestHeader());
    },
    getStudyCountryList() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-countries/`;
      return coreServices.get(url);
    },
    getStudyGroupList() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/study-groups/`;
      return coreServices.get(url);
    }
  }
}