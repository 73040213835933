export function createDocumentServices(coreServices) {
    return {

        getDocumentData() {
            const url = `${process.env.VUE_APP_BASE_URL}/api/documents/`;
            return coreServices.get(url);
        },
        getDocumentHistory() {
            const url = `${process.env.VUE_APP_BASE_URL}/api/documents/history/`;
            return coreServices.get(url);
        },
        getDocumentSigned(documentId) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/document-url/`;
            return coreServices.get(url);
        },
        getSingleHistoryRecord(recordId) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/documents/history/${recordId}/`;
            return coreServices.get(url);
        },
        getDocument(documentId) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/documents/${documentId}/`;
            return coreServices.get(url);
        },
    };
}
