<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col class="text-center">
            <v-img src="@/assets/img/logo_color.png" max-width="150px" position="center center" class="text-center"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="">
            <v-alert v-if="error" type="error" transition="scale-transition">{{ errorMessage }}</v-alert>
            <v-form class="form-forgot-password" ref="resetForm">
              <div class="div-avatar-style">
                <v-avatar color="primary" size="100">
                  <v-icon size="50" color="white">mdi-lock-reset</v-icon>
                </v-avatar>
              </div>
              <v-row justify="center">
                <v-col class="forgot-pass-colstyle" md="8">
                  <div class="text-center">
                    <span class="text-primary text-h4">Reset Your Password</span>
                  </div>
                  <v-text-field v-model="email" label="E-mail*" class="form-input pt-5 mt-4" prepend-icon="mdi-account"
                    :rules="emailRules" required variant="underlined" color="primary"></v-text-field>

                  <v-text-field v-model="newPassword" :type="showPassword ? 'text' : 'password'" label="New Password*"
                    class="form-input pt-5" prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
                    :rules="passwordRules" required variant="underlined" color="primary"></v-text-field>

                  <v-text-field v-model="confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"
                    label="Confirm Password*" class="form-input pt-5" prepend-icon="mdi-lock-check"
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showConfirmPassword = !showConfirmPassword" :rules="passwordMatchRule" required
                    variant="underlined" color="primary"></v-text-field>

                  <div class="mt-5 mb-5 text-center">
                    <v-btn variant="outlined" color="primary" rounded="lg" size="large" @click="resetPassword"
                    :disabled="!formValid">
                      Set New Password
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
            <div class="signin-text text-center">
              Remembered your password? <a class="signin-hyperlink" href="/login"> Sign in</a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
export default {
  props: ['token'],
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);

    return {
      userServices: userServicesInstance,
    };
  },
  data() {
    return {
      email: '',
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      error: false,
      errorMessage: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be at least 8 characters long',
        v => /[A-Z]/.test(v) || 'Password must contain at least one uppercase letter',
        v => /[a-z]/.test(v) || 'Password must contain at least one lowercase letter',
        v => /[0-9]/.test(v) || 'Password must contain at least one number',
        v => /[\W_]/.test(v) || 'Password must contain at least one special character',
      ],
      passwordMatchRule: [
        () => this.newPassword === this.confirmPassword || 'Passwords do not match',
      ],
    };
  },
  methods: {
    resetPassword() {
      if (!this.$refs.resetForm.validate()) {
        return;
      }
      const formData = new FormData();
      formData.append('email', this.email);
      formData.append('new_password', this.newPassword);
      formData.append('token', this.token);
      this.userServices.confirmForgotPassword(formData)
        .then(() => {
          this.$router.push('/login');
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.response.data.detail || 'An unexpected error occurred.';
          setTimeout(() => {
            this.error = false;
          }, 5000);
        });
    },
  },
  computed: {
    formValid() {
      return this.email !== '' && this.newPassword !== '' && this.confirmPassword !== '' && this.newPassword === this.confirmPassword;
    },
  },
};
</script>


<style scoped>
.div-avatar-style {
  margin: 2em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.signin-text {
  width: 100%;
  text-align: center;
  text-decoration: none;
}
</style>