<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card @dragover="dragover" @dragleave="dragleave" @drop="drop" class="file-card" elevation="2">
          <v-file-input id="file" ref="file" counter multiple name="fileToUpload" placeholder="Select your file"
            prepend-icon="mdi-paperclip" variant="underlined" color="primary" enctype="multipart/form-data" :show-size="1000"
            class="upload-input" @change="checkFile($event)" @click:clear="onFileClear"
            :accept="Constants.ACCEPTED_FILE_EXTENSIONS.join(', ')">
            <template v-slot:selection>
              <v-chip v-for="file in selectedFiles" :key="file.name" dark label small>{{ file.name }}</v-chip>
            </template>
          </v-file-input>
          <v-col>
            <v-row>
              <AlertMessage :colorTextClass="!uploadButtonFail ? '' : 'text-error'"
                :colorIcon="!uploadButtonFail ? 'primary' : 'error'" :icon="'info'"
                :message="acceptedFileExtensionInfoMessage">
              </AlertMessage>
            </v-row>
            <v-row v-if="jsonError || jsonSuccess" class="mt-10 ml-5">
              <v-alert variant="outlined" v-if="jsonError" type="error" dismissible>{{ jsonError }}</v-alert>
              <v-alert variant="outlined" v-if="jsonSuccess" type="success" dismissible>{{ jsonSuccess }}</v-alert>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <vue-json-pretty class="my-5 ml-10" :data="jsonPreview" v-if="jsonPreview" />
                  </v-col>
                </v-row>
                <v-row>
                  <div v-if="csvPreview.length > 0" class="table-container">
                    <table class="csv-preview">
                      <thead>
                        <tr>
                          <th v-for="header in csvPreview[0]" :key="header">{{ header }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in csvPreview.slice(1)" :key="index">
                          <td v-for="cell in row" :key="cell">{{ cell }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </v-row>
              </v-col>
              <pdf-viewer class="pdf-viewer" v-if="filesEncoded.length > 0" :filesEncoded="filesEncoded">
              </pdf-viewer>
              <div v-if="urls.length > 0">
                <div v-for="url in filesToString" :key="url">
                  <img draggable="false" class="preview-img-visualizate my-10 py-5" v-show="url" type="image/png"
                    :src="url" />
                </div>
              </div>
              <v-row class="d-flex justify-center align-center">
                <v-col>
                  <div id="dicomImageContainer" ref="dicomImageContainer" v-if="dicomImageVisible"
                    class="dicom-image-container">

                    <v-row>
                      <v-card>
                        <div id="dicomImage" ref="dicomImage" class="dicom-image"></div>
                      </v-card>
                    </v-row>
                    <v-row class="mt-9 ml-3 mb-4">
                      <div v-if="stack.imageIds.length > 1">
                            <ul>
                              <li>Left mouse button: Zoom</li>
                              <li>Middle mouse button: Pan</li>
                              <li>Mouse wheel: Scroll between images</li>
                              <li>Right mouse button: Measure</li>
                            </ul>
                          </div>
                          <div v-else>
                            <ul>
                              <li>Left mouse button: Zoom</li>
                              <li>Middle mouse button: Pan</li>
                              <li>Right mouse button: Measure</li>
                            </ul>
                          </div>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
          <div class="upload-style">
            <v-checkbox v-model="agree" color="primary">
              <template v-slot:label>
                I agree with the&nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a target="_blank" :href="getTermsAndConditionsUrl()" @click.stop v-on="on" class="text-primary">
                      terms and conditions
                    </a>
                  </template>
                  Terms and conditions
                </v-tooltip>
              </template>
            </v-checkbox>
            <AlertMessage v-if="uploadButtonFail" :klass="'text-center'" :colorTextClass="'red--text'" :colorIcon="'red'"
              :icon="'error'" :message="acceptedFileExtensionInfoMessage"></AlertMessage>
            <div class="text-center">
              <v-btn class="ma-2" size="large" :class="{ 'disabled-button': isUploadButtonDisabled() }" :disabled="isUploadButtonDisabled()"
              @click="submitUpload" variant="outlined" color="primary" rounded="lg">
                Upload File <v-icon dark class="ml-2">mdi-cloud-upload</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PdfViewer from "../components/PreviewPdfFile.vue";
import { createCoreServices } from '../services/coreService';
import { createFileServices } from "../services/fileService";
import { environmentService } from "../services/environmentService";
import AlertMessage from "@/components/generic/AlertMessage.vue";
import { Constants } from "../store/constants";
import Papa from 'papaparse';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import * as cornerstone from 'cornerstone-core';
import * as cornerstoneTools from 'cornerstone-tools';
import * as cornerstoneMath from 'cornerstone-math';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import Hammer from 'hammerjs';

// Inicializar cornerstoneWADOImageLoader
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.external.Hammer = Hammer;

cornerstoneWADOImageLoader.configure({
	beforeSend: function (xhr) {
		xhr.setRequestHeader('Accept', 'image/jpeg');
	}
});

cornerstone.registerImageLoader('wadouri', cornerstoneWADOImageLoader.wadouri.loadImage);
cornerstoneTools.init();

export default {
	name: "uploadFiles",
	components: {
		PdfViewer,
		AlertMessage,
		VueJsonPretty
	},
	data() {
		return {
			Constants,
			selectedFiles: [],
			base64Files: [],
			csvFiles: [],
			csvPreview: [],
			loading: false,
			agree: false,
			alert: false,
			urls: [],
			uploadButtonFail: false,
			coreServicesInstance: null,
			fileServices: null,
			jsonPreview: null,
			jsonError: null,
			jsonSuccess: null,
			dicomImageVisible: false,
			stack: {
				currentImageIdIndex: 0,
				imageIds: []
			}
		};
	},
	mounted() {
		this.coreServicesInstance = createCoreServices();
		this.fileServices = createFileServices(this.coreServicesInstance);
	},
	computed: {
		filesEncoded() {
			return JSON.parse(JSON.stringify(this.base64Files));
		},
		filesToString() {
			return this.urls.map((url) => `data:image/png;base64,${url}`);
		},
		acceptedFileExtensionInfoMessage() {
			return `Only files with extension: ${Constants.ACCEPTED_FILE_EXTENSIONS.join(
				", "
			)}`;
		},
	},
	methods: {
		isUploadButtonDisabled() {
			return (
				this.uploadButtonFail || !this.agree || this.selectedFiles.length === 0
			);
		},
		onFileClear() {
			this.selectedFiles = [];
			this.uploadButtonFail = false;
			this.urls = [];
			this.base64Files = [];
			this.csvPreview = [];
			this.jsonPreview = null;
			this.jsonError = null;
			this.jsonSuccess = null;
			this.dicomImageVisible = false;
			this.stack = { currentImageIdIndex: 0, imageIds: [] };
		},
		checkFile(event) {
			this.selectedFiles = [...event.target.files];
			this.validateFiles();
		},
		validateFiles() {
			let fail = false;
			this.urls = [];
			this.base64Files = [];
			this.uploadButtonFail = this.selectedFiles.some((file) => {
				const ext = file.name.split(".").pop();
				if (!Constants.ACCEPTED_FILE_EXTENSIONS.includes(`.${ext}`)) {
					fail = true;
					return true;
				}
				return false;
			});
			if (!fail) {
				this.handleFiles();
			}
		},
		async handleFiles() {
			const promises = this.selectedFiles.map((file) => this.handleFile(file));
			await Promise.all(promises);
		},
		async handleFile(file) {
			const ext = file.name.split(".").pop().toLowerCase();
			if (ext === "csv") {
				this.previewCSV(file);
				console.log("csv: ", file.name);
				this.csvFiles.push(file);
			}
			else if (ext === "json") {
				this.previewJSON(file);
				console.log("json: ", file.name);
			} else {
				const base64 = await this.fileToBase64(file);
				if (ext === "pdf") {
					this.base64Files.push(base64);
				}
				else if (ext === "dcm") {
					this.previewDICOM(file);
					console.log("dicom: ", file.name);
				} else {
					this.urls.push(base64);
				}
			}
		},
		async convertFilesToBase64() {
			const promises = this.selectedFiles.map((file) =>
				this.fileToBase64(file)
			);
			const results = await Promise.all(promises);
			results.forEach((base64, index) => {
				const ext = this.selectedFiles[index].name.split(".").pop();
				if (ext === "pdf") {
					this.base64Files.push(base64);
				} else {
					this.urls.push(base64);
				}
			});
		},
		previewCSV(file) {
			Papa.parse(file, {
				complete: (results) => {
					this.csvPreview = results.data.slice(0, 6);
				},
				error: (error) => {
					console.error("Error parsing CSV:", error);
				},
				preview: 6
			});
		},
		fileToBase64(file) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = () => {
					const base64 = reader.result.split(",")[1];
					resolve(base64);
				};
				reader.readAsDataURL(file);
			});
		},
		submitUpload() {

			const archive = this.$refs.file;
			this.loading = true;
			const responses = this.fileServices.uploadFile(archive);
			Promise.all(responses)
				.then((results) => {
					console.log("Results");
					console.log(JSON.stringify(results));
					results.forEach((res) => {
						this.data = res.data.status;
						if (this.data === "uploaded") {
							this.loading = false;
						} else {
							this.loading = false;
							// TODO Mensaje?
							// logoutServices.logout();
						}
					});
					this.$router.push("/document/preclassification");
				})
				.catch((error) => {
					this.loading = false;
					this.uploadDisabled = !this.uploadDisabled;
					console.log(error);
				});
		},
		dragover(event) {
			event.preventDefault();
		},
		dragleave(event) {
			event.preventDefault();
		},
		drop(event) {
			event.preventDefault();
			this.selectedFiles = [...event.dataTransfer.files];
			this.validateFiles();
		},
		getTermsAndConditionsUrl() {
			return environmentService.getTermsAndConditionsUrl();
		},
		previewJSON(file) {
			const reader = new FileReader();
			reader.onload = (event) => {
				try {
					this.jsonPreview = JSON.parse(event.target.result);
					this.jsonError = null;
					this.jsonSuccess = "Valid JSON format";
				} catch (error) {
					console.error("Error parsing JSON:", error);
					this.jsonPreview = null;
					this.jsonError = "Invalid JSON file structure. Please check file.";
					this.jsonSuccess = null;
				}
			};
			reader.readAsText(file);
		},
		previewDICOM(file) {
			const reader = new FileReader();
			reader.onload = async (event) => {
				try {
					const arrayBuffer = event.target.result;
					const byteArray = new Uint8Array(arrayBuffer);
					const dataSet = dicomParser.parseDicom(byteArray);

					const patientName = dataSet.string('x00100010');
					const patientID = dataSet.string('x00100020');
					console.log('Nombre del paciente:', patientName);
					console.log('ID del paciente:', patientID);

					const imageId = `wadouri:${URL.createObjectURL(file)}`;
					this.stack.imageIds.push(imageId);

					if (this.stack.imageIds.length === 1) {
						this.dicomImageVisible = true;

						await this.$nextTick();

						const element = this.$refs.dicomImage;
						console.log('dicomImage element:', element);

						if (element) {
							cornerstone.loadImage(imageId).then((image) => {
								cornerstone.enable(element);
								cornerstone.displayImage(element, image);

								cornerstoneTools.init({
									showSVGCursors: true,
								});

								cornerstoneTools.addTool(cornerstoneTools.StackScrollMouseWheelTool);
								cornerstoneTools.addTool(cornerstoneTools.ZoomTool, {
									configuration: {
										invert: false,
										preventZoomOutsideImage: true,
										minScale: 0.1,
										maxScale: 20.0,
									},
								});

								cornerstoneTools.addTool(cornerstoneTools.PanTool);
								cornerstoneTools.addTool(cornerstoneTools.LengthTool);

								if (this.stack.imageIds.length > 1) {
									cornerstoneTools.addTool(cornerstoneTools.StackScrollMouseWheelTool);
									cornerstoneTools.setToolActive('StackScrollMouseWheel', {});
								}

								cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });
								cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 });
								cornerstoneTools.setToolActive('Length', { mouseButtonMask: 2 });


								const stackState = {
									currentImageIdIndex: this.stack.currentImageIdIndex,
									imageIds: this.stack.imageIds
								};
								cornerstoneTools.addStackStateManager(element, ['stack']);
								cornerstoneTools.addToolState(element, 'stack', stackState);

								this.dicomImageVisible = true;
								console.log("Image displayed successfully.");

								// Deshabilitamos el boton dcho
								element.addEventListener('contextmenu', function (event) {
									event.preventDefault();
								});

							}).catch((error) => {
								console.error("Error loading image:", error);
							});
						} else {
							console.error('dicomImage element is not defined');
						}
					}
				} catch (error) {
					console.error("Error in reader.onload:", error);
				}
			};
			reader.readAsArrayBuffer(file);
		}
	}
};
</script>

<style scoped>
.file-card {
	padding: 40px;
	width: 100%;
	margin: 0 auto;
}

.pdf-viewer {
	margin: 0 auto;
	margin-top: 60px !important;
	margin-bottom: 40px !important;
}

.upload-input {
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.disabled-button {
	color: rgba(0, 0, 0, 0.26) !important;
	background: none !important;
}

.upload-style {
	margin-left: -7px;
}

.alertbox {
	position: fixed;
	left: 70%;
	margin: 0 auto;
}

.table-container {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 0 8px;
	margin-top: 20px;
}

.csv-preview {
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
}


.csv-preview th,
.csv-preview td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

.csv-preview th {
	background-color: #f2f2f2;
	font-weight: bold;
}


.dicom-image-container {

	width: auto;
	height: auto;
	margin: 70px 0 50px 0;
}

.dicom-image {
	width: 512px;
	height: 512px;
}

::v-deep .v-data-table-header__content {
	font-weight: bold !important;
}
</style>
