<template>
  <div class="edit-div">
    <overlay-loader :loading="loading"></overlay-loader>
    <v-alert id="alert-changepassword" v-model="alertSuccess" outlined text width=250 class="mt-4" type="success">
      Password successfully changed
    </v-alert>
    <v-alert class="mt-4" v-if="alert" v-model="alert" type="error" width=250 transition="scale-transition">{{ error }}
    </v-alert>
    <div>
      <v-row>
        <v-col>
          <v-text-field id="change-password-oldpass-field" v-model="oldPassword" label="Old password*" type="password"
            class="form-input" placeholder="Password" required variant="underlined" color="primary"></v-text-field>
          <v-text-field id="change-password-newpass-field" v-model="newPassword" label="New password*" type="password"
            class="form-input" :rules="[passwordRules]" placeholder="Password" variant="underlined" required color="primary"></v-text-field>
          <v-text-field id="change-password-repeatpass-field" v-model="repeatedPassword" label="Repeat new password*"
            type="password" class="form-input" placeholder="Password" variant="underlined" required color="primary"></v-text-field>
        </v-col>
        <v-col>
          <div class="change-font">
            <v-card-text class="password-text">Password must contain:</v-card-text>
            <v-list-item>
              <v-icon @change="colorLenRules" :color="colorLength">mdi-check-bold
              </v-icon>{{ lengthRule }}
            </v-list-item><br>
            <v-list-item>
              <v-icon @change="colorUpperRules" :color="colorUpper">mdi-check-bold
              </v-icon>{{ upperRule }}
            </v-list-item><br>
            <v-list-item>
              <v-icon @change="colorLowerRules" :color="colorLower">mdi-check-bold
              </v-icon>{{ lowerRule }}
            </v-list-item><br>
            <v-list-item>
              <v-icon @change="colorNumRules" :color="colorNumber">mdi-check-bold
              </v-icon>{{ numberRule }}
            </v-list-item><br>
            <v-list-item>
              <v-icon @change="colorCharRules" :color="colorCharacter">mdi-check-bold
              </v-icon>{{ characterRule }}
            </v-list-item><br>
          </div>
          <div class="change-font">
            <v-btn id="change-password-btn" class="ml-5 mb-4" @click="savePassword" :disabled="formValid" variant="outlined"
            color="primary" rounded="lg">
              Change my password
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
import { PasswordChecker } from '@/utils/passwordUtilities';

export default {
  name: 'ChangePassword',
  components: {
  },
  setup() {
    const coreServicesInstance = createCoreServices();
    const userServicesInstance = createUserServices(coreServicesInstance);
    return {
      userServices: userServicesInstance,
    };
  },
  data() {
    return {
      PasswordChecker,
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      newPassword: '',
      repeatedPassword: '',
      oldPassword: '',
      userId: '',
      error: '',
      alert: false,
      alertSuccess: false,
      loading: false,
      passwordLength: false,
      lengthRule: 'At least 8 characters.',
      upperRule: 'At least 1 upper case letter (A..Z)',
      lowerRule: 'At least 1 lower case letter (a..z)',
      numberRule: 'At least 1 number (0...9)',
      characterRule: 'At least 1 special character (/..-)',
      colorLength: 'grey',
      colorUpper: 'grey',
      colorLower: 'grey',
      colorNumber: 'grey',
      colorCharacter: 'grey',
    };
  },
  computed: {
    passwordRules() {
      return this.passwordStrong();
    },
    colorLenRules() {
      return this.passwordLen();
    },
    colorUpperRules() {
      return this.passwordUpper();
    },
    colorLowerRules() {
      return this.passwordLower();
    },
    colorNumRules() {
      return this.passwordNum();
    },
    colorCharRules() {
      return this.passwordChar();
    },
    formValid() {
      const validPassword = this.passwordStrong(this.newPassword);
      return !((validPassword)
        && (this.oldPassword !== this.newPassword)
        && (this.newPassword === this.repeatedPassword)
        && (this.oldPassword !== '' && this.newPassword !== ''
          && this.repeatedPassword !== '')
        && this.colorLenRules
        && this.colorUpperRules
        && this.colorLowerRules
        && this.colorNumRules
        && this.colorCharRules);
    },
  },
  methods: {
    passwordStrong() {
      return PasswordChecker.passwordStrong(this.newPassword);
    },
    passwordLen() {
      const result = PasswordChecker.passwordLength(this.newPassword);
      this.colorLength = result ? 'green' : '';
      return result;
    },
    passwordUpper() {
      const result = PasswordChecker.passwordUpper(this.newPassword);
      this.colorUpper = result ? 'green' : '';
      return result;
    },
    passwordLower() {
      const result = PasswordChecker.passwordLower(this.newPassword);
      this.colorLower = result ? 'green' : '';
      return result;
    },
    passwordNum() {
      const result = PasswordChecker.passwordNum(this.newPassword);
      this.colorNumber = result ? 'green' : '';
      return result;
    },
    passwordChar() {
      const result = PasswordChecker.passwordChar(this.newPassword);
      this.colorCharacter = result ? 'green' : '';
      return result;
    },
    savePassword() {
      this.loading = true;
      const formData = new FormData();
      formData.append('old_password', this.oldPassword);
      formData.append('new_password', this.newPassword);
      this.userServices.changePassword(formData)
        .then((response) => {
          this.loading = false;
          this.auxSavePassword(response);
        })
        .catch((err) => {
          this.alert = true;
          this.loading = false;
          this.error = 'Error with changing password';
          console.log(err);
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
    },
    auxSavePassword(response) {
      if (response.status === 200 || response.status === 204) {
        this.alertSuccess = true;
        setTimeout(() => {
          this.alertSuccess = false;
          this.oldPassword = '';
          this.newPassword = '';
          this.repeatedPassword = '';
          this.$router.push('/home');
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
.save-style {
  width: auto;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.change-font {
  margin-top: 40px;
  margin-left: 40px;
  font-size: 16px;
  line-height: 0;
}

.title-password {
  font-size: 20px !important;
}

.password-text {
  font-size: 20px !important;
  /* margin-top: -20px; */
  /* margin-bottom: 20px; */
}

.form-input {
  margin: 24px;
}

.v-expansion-panel {
  max-width: 900px !important;
  border-radius: 16px !important;
}

.v-expansion-panels {
  border-radius: 16px !important;
}
</style>
