import axios from 'axios';
import { getCookie } from '../utils/cookies';

export function createCoreServices() {
  return {
    async get(url) {
      return axios.get(url, { headers: this.getRequestHeader() });
    },
    async downloadBlob(url, data) {
      return axios.post(url, data, { headers: this.getRequestHeader(), responseType: 'blob' });
    },
    async post(url, data, headers) {
      return axios.post(url, data, { headers: headers });
    },
    async put(url, data) {
      return axios.put(url, data, { headers: this.getRequestHeader() });
    },
    async patch(url, data) {
      return axios.patch(url, data, { headers: this.getRequestHeader() });
    },
    async delete(url) {
      return axios.delete(url, { headers: this.getRequestHeader() });
    },

    getRequestHeader() {
      const accessToken = getCookie('accessToken');
      return {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
    },
    getMultipartRequestHeader() {
      const accessToken = getCookie('accessToken');
      return {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      };
    },
  };
}
