<template>
  <div class="pdf-div" v-if="files.length > 0">
    <v-row>
      <v-col v-for="(file, index) in files" :key="`file-${index}`" sm="12">
        <div class="pdf-header">
          <v-row class="pdf-title-row">
            <v-col>
              <div class="pdf-title">{{ file.title }}</div>
            </v-col>
          </v-row>
          <v-row justify="end" class="file-controls-row">
            <v-col cols="auto">
              <div class="file-controls">
                <v-btn icon x-small @click="zoomIn(index)">
                  <v-icon size="18">mdi-magnify-plus</v-icon>
                </v-btn>
                <v-btn icon x-small @click="zoomOut(index)">
                  <v-icon size="18">mdi-magnify-minus</v-icon>
                </v-btn>
                <v-btn icon x-small v-if="canDownloadPdf" @click="downloadPdf(file, `documento-${index}.pdf`)">
                  <v-icon size="18">mdi-download</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="pdf-container">
          <v-col class="pa-2 my-7" v-for="(canvasId, pageIndex) in file.pages" :key="`canvas-${index}-${pageIndex}`">
            <canvas :id="`canvas-${index}-${pageIndex}`" class="the-canvas"></canvas>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf.mjs';
GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.mjs';

import { roleManager } from '@/store/roleManager';

export default {
  name: 'PdfViewer',
  props: {
    files: {
      type: Array,
      required: true,
    },
    download: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      scales: {},
    };
  },
  mounted() {
    this.files.forEach((file, index) => {
      this.scales[index] = 1;
      this.initPdfRendering(file, index);
    });
  },
  computed: {
    canDownloadPdf() {
      return roleManager.isResearcher() || roleManager.isVerifier();
    },
  },
  methods: {
    async initPdfRendering(file, fileIndex) {
      try {
        let loadingTask = file.url ? getDocument(file.url) : getDocument({ data: atob(file.base64) });
        const pdf = await loadingTask.promise;
        file.pages = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
        file.pages.forEach((pageIndex) => this.renderPage(pdf, fileIndex, pageIndex));
      } catch (error) {
        console.error("Failed to load PDF:", error);
      }
    },
    async renderPage(pdf, fileIndex, pageIndex) {
      try {
        const page = await pdf.getPage(pageIndex);
        const scale = this.scales[fileIndex];
        const canvas = document.getElementById(`canvas-${fileIndex}-${pageIndex - 1}`);
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale });
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: context,
          viewport
        };
        await page.render(renderContext);
      } catch (error) {
        console.error("Failed to render page:", error);
      }
    },
    zoomIn(fileIndex) {
      if (this.scales[fileIndex] < 2) {
        this.scales[fileIndex] *= 1.2;
        this.initPdfRendering(this.files[fileIndex], fileIndex);
      }
    },
    zoomOut(fileIndex) {
      if (this.scales[fileIndex] > 0.2) {
        this.scales[fileIndex] /= 1.2;
        this.initPdfRendering(this.files[fileIndex], fileIndex);
      }
    },
    downloadPdf(file, filename) {
      const link = document.createElement('a');
      link.href = file.url ? file.url : `data:application/pdf;base64,${file.base64}`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.pdf-div {
  margin: 30px 4em !important;
}

.pdf-header {
  width: 100%;
}

.pdf-title-row {
  width: 100%;
  padding: 0;
  margin-top: -25px !important;
}

.file-controls-row {
  width: 100%;
  margin-bottom: 10px;
  margin-bottom: 0;
  padding: 0;
  margin-top: -15px !important;
}

.pdf-title {
  text-align: center;
  font-size: 1.7em;
}

.file-controls {
  display: flex;
  gap: 8px;
}

.the-canvas {
  border: 1px solid black;
  margin-bottom: 10px;
}

.pdf-container {
  overflow: auto;
  max-height: 800px;
  position: relative;
  background: none;
}
</style>
