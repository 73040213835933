<template>
  <v-chip :class="getStatusColorClass()">
    <span>{{ status_name }}</span>
  </v-chip>
</template>

<script>
import { Constants } from '@/store/constants';

export default {
  name: 'DataStatusLabel',
  data() {
    return {
      Constants,
    };
  },
  props: {
    status_name: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    getStatusColorClass() {
      if (this.status_name === Constants.STATUS_RAW) {
        return 'status-label__raw';
      }
      if (this.status_name === Constants.STATUS_UPLOADED) {
        return 'status-label__uploaded';
      }
      if (this.status_name === Constants.STATUS_VERIFIED) {
        return 'status-label__verified';
      }
      if (this.status_name === Constants.STATUS_REJECTED) {
        return 'status-label__rejected';
      }
      if (this.status_name === Constants.STATUS_APPROVED) {
        return 'status-label__approved';
      }
      if (this.status_name === Constants.STATUS_PROCESSED) {
        return 'status-label__processed';
      }
      return 'status-label__default';
    },
  },
};
</script>