<template>
  <v-app>
    <v-main class="ma-0">
      <v-row class="fill-height ma-0">
        <v-col cols="12" md="6" class="d-flex justify-center align-center dl-bg-gradient">
          <v-img class="responsive-img" alt="logo" src="@/assets/img/logo_white.png"></v-img>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="8" md="6">
                <v-sheet width="350" class="mx-auto">
                  <v-form>
                    <v-text-field id="study" prepend-inner-icon="mdi-book-open" variant="underlined" label="Study"
                      v-model="study" placeholder="Study Code" type="text" :rules="studyRules" required color="primary">
                    </v-text-field>
                    <v-text-field class="mt-1" id="email" prepend-inner-icon="mdi-account" variant="underlined"
                      label="E-mail" v-model="email" placeholder="Email" type="email" :rules="emailRules" required
                      color="primary">
                    </v-text-field>
                    <v-text-field class="mt-1 mb-0" id="password" color="primary"
                      :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                      prepend-inner-icon="mdi-lock" variant="underlined" label="Password" v-model="password"
                      placeholder="Password" required :rules="passwordRules" @click:append-inner="toggleVisibility"
                      @keyup.enter="login"></v-text-field>
                    <div class="text-subtitle-1 text-medium-emphasis d-flex justify-space-between">
                      <router-link class="text-caption text-decoration-none text-primary border-primary"
                      to="/forgotPassword">
                        Forgot login password?
                      </router-link>
                    </div>
                    <v-btn class="mt-5 login-button" color="primary" block rounded="xl" size="x-large" variant="outlined"
                    @click="login" :disabled="formValid">
                      Sign In
                    </v-btn>
                  </v-form>
                </v-sheet>
              </v-col>
            </v-row>
            <div style="position:absolute; bottom:5px; right:5px; font-size: 10px;">
              <p><strong>Version: </strong>{{ version }}</p>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { createCoreServices } from '../services/coreService';
import { createUserServices } from '../services/userService';
import { APP_VERSION } from '@/envConfig';
import { getAccessTokenExpiration, getRefreshTokenExpiration, getCookie, setCookie, removeCookies } from '../utils/cookies';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const coreServices = createCoreServices();
    const { Login } = createUserServices(coreServices);
    const email = ref('');
    const password = ref('');
    const study = ref('');
    const visible = ref(false);
    const version = ref(APP_VERSION);
    const error = ref('');
    const emailRules = [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ];

    const passwordRules = [
      v => !!v || 'Password is required',
      v => (v && v.length >= 6) || 'Password must be more than 6 characters',
    ];

    const studyRules = [
      v => !!v || 'Study is required',
    ];

    const login = async () => {
      store.dispatch('setLoading', true);
      removeCookies();
      try {
        const response = await Login({
          email: email.value,
          password: password.value,
          study: study.value,
        });
        auxLogin(response);
      } catch (error) {
        console.log(JSON.stringify(error));
        store.commit('setLoading', false);
        if (error.response && error.response.status === 400) {
          const errorCode = error.response.data.code;
          if (errorCode === 'password_expired') {
            toast("Your password has expired. Check your email in order to update it.", {
              type: "error",
              autoClose: 7000,
              transition: "slide",
              dangerouslyHTMLString: true
            });
          } else {
            toast("Invalid login credentials. Please try again.", {
              type: "error",
              transition: "slide",
              dangerouslyHTMLString: true
            });
          }
        } else {
          toast("An error occurred. Please try again.", {
            type: "error",
            transition: "slide",
            dangerouslyHTMLString: true
          });
        }

        console.log("Error durante el login:", error);
      }
    };


    const toggleVisibility = () => {
      visible.value = !visible.value;
    };

    const auxLogin = (response) => {

      store.commit('setLoading', false);
      try {
        setCookie('accessToken', response.data.tokens.access, getAccessTokenExpiration());
        setCookie('refreshToken', response.data.tokens.refresh, getRefreshTokenExpiration());
        if (!getCookie('accessToken') || getCookie('accessToken') === undefined) {
          store.commit('setLoading', false);
          toast(
            "This site requires permissions to store data on your device. Please allow storage in your browser settings.",
            {
              type: "warning",
              transition: "slide",
              dangerouslyHTMLString: true
            }
          );
        }
        store.commit('setUser', response.data.user);
        store.commit('setTokens', response.data.tokens);
        store.commit('setStudy', study.value);

        const role = response.data.user["role"] ? response.data.user["role"] : "none";
        setTimeout(() => {
          setCookie('role', role, getAccessTokenExpiration());
          store.commit('setLoading', false);
          router.push('/home');
        }, 1500);
      } catch (e) {
        console.error("Error setting cookies or handling login:", e);
      }
    };

    const formValid = computed(() => {
      const emailValid = /.+@.+\..+/.test(email.value);
      const passwordValid = password.value && password.value.length >= 6;
      const studyValid = !!study.value;
      return !(emailValid && passwordValid && studyValid);
    });

    return {
      email,
      password,
      study,
      visible,
      version,
      error,
      emailRules,
      passwordRules,
      studyRules,
      login,
      toggleVisibility,
      auxLogin,
      formValid
    };
  },
  mounted() {
    try {
      this.$store.dispatch('resetState');
    } catch (e) {
      console.error("Error al acceder a store", e);
    }
  },
  methods: {
    getVersionFromProject(jsonFile) {
      return jsonFile.version;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-img {
  max-width: 350px;
  height: 190px;
  margin-left: -20px;
  margin-top: 0;
}

.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.left {
  left: 0;
}

.right {
  right: 0;
  background-color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
