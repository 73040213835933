export function cookieAsObject(cookie) {
    return cookie.split(/[;] */).reduce((result, pairStr) => {
      const arr = pairStr.split('=');
      const [key, value] = arr;
      if (arr.length === 2) {
        result[key] = value; // eslint-disable-line no-param-reassign
      }
      return result;
    }, {});
  }
  