export function createStudyServices(coreServices) {
    return {
        getStudiesList() {
            const url = `${process.env.VUE_APP_BASE_URL}/api/studies/`;
            return coreServices.get(url);
        },
        createStudy(data) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/studies/`;
            return coreServices.post(url, data);
        },
        getStudyData(studyId) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/studies/${studyId}/`;
            return coreServices.get(url);
        },
        updateStudyData(studyId, data) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/studies/${studyId}/`;
            return coreServices.put(url, data);
        },
        deleteStudy(studyId) {
            const url = `${process.env.VUE_APP_BASE_URL}/api/studies/${studyId}/`;
            return coreServices.delete(url);
        },
    };
}
